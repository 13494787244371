import React from "react";
import { Container, Row, Col, NavItem } from "react-bootstrap";
import "../../../assets/css/About/style.css";
import { Link, Switch, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
//New imports About US
import purpose from "../../../assets/img/about/about_first_big.jpg";
import about2 from "../../../assets/img/about/about_second_big.jpg";
import brSilk from "../../../assets/img/about/about_third_big.jpg";
import brandService from "../../../assets/img/about/about_forth_big.jpg";

export default class Representation extends React.Component {
  render() {
    return (
      <>
        <Container fluid className={"about_us_big_fluid"}>
          <Container className={"first_section_about_us"}>
            <Row>
              <Col
                xl={{ span: 6, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                xs={{ span: 12, offset: 0 }}
                className={"first_section_left_box"}
              >
                <div className={"section_for_title_desc"}>
                  <p className={"first_big_title"}>
                    <FormattedMessage id={"nav_second"} />
                  </p>
                  <p className={"second_big_title text-justify"}>
                    Մեր ընկերությունը հիմնադրվել է 2010 թվականին և այսօր
                    հանդիսանում է Հայաստանի թղթե հիգիենայի ոլորտում առաջատար
                    ձեռնարկություններից մեկը: Կազմակերպված աշխատանքի շնորհիվ
                    սեղմ ժամանակահատվածում ընկերությունն իր հաստատուն տեղն է
                    զբաղեցրել հայկական շուկայում` սպառողին առաջարկելով
                    բարձրորակ, էկոլոգիապես մաքուր և մատչելի հայկական արտադրանք։
                    Արտադրության զարգացմանը զուգահեռ ընկերությունը հանդիսանում է
                    մի շարք միջազգային ընկերությունների պաշտոնական
                    ներկայացուցիչը Հայաստանում։ Այսօր «Սոֆթ Թրեյդ» ընկերությունը
                    սպառողին է առաջարկում 1000 ից ավել ապրանքատեսակ:
                    Ընկերությունն առաքումն իրականցնում է Հայաստանի
                    Հանրապետության և Արցախի ողջ տարածքով, աշխատում է ինչպես
                    մանրածախ, մեծածախ այնպես էլ ցանցային վաճառակետերի հետ։
                  </p>
                </div>
              </Col>
              <Col
                xl={{ span: 6, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                xs={{ span: 12, offset: 0 }}
                className={"first_section_right_box"}
              >
                <div className={"first_section_right_box_img"}>
                  <img src={purpose} className={"img-fluid w-100"} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className={"second_section_about_us_fluid"}>
          <Container className={"second_section_about_us"}>
            <Row>
              <Col
                xl={{ span: 5, ofsset: 0 }}
                lg={{ span: 5, ofsset: 0 }}
                md={{ span: 5, ofsset: 0 }}
                className={"col-for-left-img mobile-size-none "}
              >
                <div className={"left-content"}>
                  <img src={about2} className={"img-fluid w-100"} alt="" />
                </div>
              </Col>
              <Col
                xl={{ span: 7, ofsset: 0 }}
                lg={{ span: 7, ofsset: 0 }}
                md={{ span: 7, ofsset: 0 }}
                className={"col-for-right-text"}
              >
                <div className={"second_section_for_title_desc"}>
                  <p className={"first_big_title"}>
                    <FormattedMessage id={"himnadrum"} />
                  </p>
                  <p className={"first_desc"}>
                    <ul>
                      <li>
                        Մեր արտադրանքի համար օգտագործում ենք ժամանակակից
                        գործարանների կողմից արտադրված միայն բարձրորակ,
                        էկոլոգիապես մաքուր 100% ցելյուլոզա, որն ունի FSC
                        վկայական։
                      </li>
                      <li>
                        Մեր հաճախորդներին առաջարկում ենք տնտեսական ապրանքների
                        լայն տեսականի լավագույն գներով։
                      </li>
                      <li>
                        Միջազգային բեռնափոխադրման համար օգտագործում ենք սեփական
                        բեռնատարները։
                      </li>
                      <li>
                        Արտադրության հոսքագծերն շարունակաբար արդիականացվում են և
                        ավելացվում, որի շնորհիվ ապահովում ենք արտադրանքի բարձր
                        որակ։
                      </li>
                      <li>
                        Արտադրանքի անվնաս պահպանման համար օգտագործում ենք
                        ժամանակակից պահեստային համակարգ։
                      </li>
                      <li>
                        Զարգացած լոգիստիկ համակարգը հնարավորություն է տալիս
                        պատվերների 95% առաքել 24 ժամվա ընթացքում Հայաստանի
                        Հանրապետության և Արցախի ամբողջ տարածքում։
                      </li>
                    </ul>
                  </p>
                </div>
              </Col>
              <Col xl={5} className={"col-for-left-img mobile-size-block mt-3"}>
                <div className={"left-content"}>
                  <img src={about2} className={"img-fluid w-100"} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className={"for_new_brands"}>
          <Row>
            <Col
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              sm={{ span: 6, offset: 0 }}
              className={"left_content"}
            >
              <div className={"section_for_title_desc"}>
                <p className={"brand-title"}>
                  <FormattedMessage id={"link_brands"} />
                </p>
                {/*<p className={'brand-desc-bold'}>*/}
                {/*    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's .*/}
                {/*</p>*/}
                <p className={"brand-desc"}>
                  Արդեն տասը տարի է, ինչ մեր ապրանքային նշանները գրավել են
                  առաջատար և իրենց ուրույն տեղը հայկական շուկայում՝ դառնալով
                  սիրելի և վստահելի արտադրանք սպառողների համար։
                </p>
                <div className={"for_link_see"}>
                  <NavLink to="/brands" style={{ textDecoration: "none" }}>
                    <FormattedMessage id={"read_more"} />
                  </NavLink>
                </div>

                <Switch></Switch>
              </div>
            </Col>
            <Col
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              sm={{ span: 6, offset: 0 }}
              className={"right_content"}
            >
              <img src={brSilk} className={"img-fluid w-100"} alt="" />
            </Col>
          </Row>
        </Container>
        <Container className={"for_new_service"}>
          <Row>
            <Col
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              sm={{ span: 6, offset: 0 }}
              className={"right_content mobile-size-none"}
            >
              <img src={brandService} className={"img-fluid w-100"} alt="" />
            </Col>
            <Col
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              sm={{ span: 6, offset: 0 }}
              className={"left_content"}
            >
              <div className={"section_for_title_desc"}>
                <p className={"brand-title"}>
                  <FormattedMessage id={"link_service"} />
                </p>

                <p className={"brand-desc"}>
                  «Սոֆթ Թրեյդ» ՍՊԸ-ն առաջարկում է պայմանագրային արտադրության,
                  HoReCa շուկայի տնտեսական ապրանքների մատակարարման և միջազգային
                  բեռնափոխադրման ծառայություններ։
                </p>
                <div className={"for_link_see"}>
                  <Link to="/service" style={{ textDecoration: "none" }}>
                    <FormattedMessage id={"read_more"} />
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              sm={{ span: 6, offset: 0 }}
              className={"right_content mobile-size-block"}
            >
              <img src={brandService} className={"img-fluid w-100"} alt="" />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
