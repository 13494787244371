import React  from 'react';
import {Col, Container, Row ,Tab, Navbar,Nav,NavDropdown,Form} from 'react-bootstrap';
import '../../../assets/css/Product/style.css';
import {FormattedMessage} from 'react-intl';

import NapkinsBox from '../ProductComponents/NapkinsBox';
import NapkinsPol from "../ProductComponents/NapkinsPol";
import Toilet1 from "../ProductComponents/Toilet1";
import Toilet2 from "../ProductComponents/Toilet2";
import Kitchen2 from "../ProductComponents/Kitchen2";
import Kitchen3 from "../ProductComponents/Kitchen3";
import Kitchen4 from "../ProductComponents/Kitchen4";
import WetNapkins from "../ProductComponents/wetNapkins";
import Cotton from "../ProductComponents/Cotton";
import Other from "../ProductComponents/Other";
import Profesional from "../ProductComponents/Profesional";
import Toilet3 from "../ProductComponents/Toilet3";
import Toilet4 from "../ProductComponents/Toilet4";
import { Link } from 'react-router-dom';
export default class Products extends React.Component {
    render() {
        return(
            <>
                <Container fluid id={'product-first-section'}>
                    <Container>
                        <Tab.Container id={'left-tabs'}  defaultActiveKey={'napkins_box'}>
                            <Row>
                                <Col xl={3} lg={4} md={4} sm={12} className={'left_content_for_height_bg'}>
                                    <Row>
                                        <Col xl={12} className={''}>
                                            <Nav className={'flex-column left-side-nav'} id={'tab-computer-size'}>
                                                <Nav.Item className={'dropdaowns-menu'}>
                                                    <NavDropdown title={<FormattedMessage id={'product_menu_first'} />} id="collasible-nav-dropdown">
                                                        <NavDropdown.Item  eventKey={'napkins_box'}>
                                                            <FormattedMessage id={"napkins_tup"} />
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item eventKey={'napkins_pol'} >
                                                            <FormattedMessage id={"napkins_celafon"} />
                                                        </NavDropdown.Item>
                                                    </NavDropdown>
                                                </Nav.Item>
                                                <Nav.Item className={'dropdaowns-menu'}>
                                                    <NavDropdown title={<FormattedMessage id={'product_menu_second'} />} id="collasible-nav-dropdown">
                                                        <NavDropdown.Item  eventKey={'toilet1'} id={'kkkk'}>
                                                            <FormattedMessage id={"shert1"} />
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item  eventKey={'toilet2'}>
                                                            <FormattedMessage id={"shert2"} />
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item eventKey={'toilet3'}>
                                                            <FormattedMessage id={"shert3"} />
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item eventKey={'toilet4'}>
                                                            <FormattedMessage id={"shert4"} />
                                                        </NavDropdown.Item>
                                                    </NavDropdown>
                                                </Nav.Item>
                                                <Nav.Item className={'dropdaowns-menu'}>
                                                    <NavDropdown title={<FormattedMessage id={'product_menu_third'} />} id="collasible-nav-dropdown">
                                                        <NavDropdown.Item eventKey={'kitchen2'}>
                                                            <FormattedMessage id={"shert2"} />
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item eventKey={'kitchen3'}>
                                                            <FormattedMessage id={"shert3"} />
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item eventKey={'kitchen4'}>
                                                            <FormattedMessage id={"shert4"} />
                                                        </NavDropdown.Item>
                                                    </NavDropdown>
                                                </Nav.Item>

                                                <Nav.Item className={'dropdaowns-menu'} id="collasible-nav-dropdown-other">
                                                    <NavDropdown.Item eventKey={'wetNapkins'} id="collasible-nav-dropdown">
                                                        <FormattedMessage id={'product_menu_forth'} />
                                                    </NavDropdown.Item>
                                                </Nav.Item>

                                                <Nav.Item className={'dropdaowns-menu'} id="collasible-nav-dropdown-other">
                                                    <NavDropdown.Item eventKey={'cotton'} id="collasible-nav-dropdown">
                                                        <FormattedMessage id={'product_menu_fifth'} />
                                                    </NavDropdown.Item>
                                                </Nav.Item>

                                                <Nav.Item className={'dropdaowns-menu'} id="collasible-nav-dropdown-other">
                                                    <NavDropdown.Item eventKey={'profesional'} id="collasible-nav-dropdown">
                                                        <FormattedMessage id={'product_menu_sixth'} />
                                                    </NavDropdown.Item>
                                                </Nav.Item>

                                                <Nav.Item className={'dropdaowns-menu'} id="collasible-nav-dropdown-other">
                                                    <NavDropdown.Item eventKey={'other'} id="collasible-nav-dropdown">
                                                        <FormattedMessage id={'product_menu_seventh'} />
                                                    </NavDropdown.Item>
                                                </Nav.Item>
                                            </Nav>
                                            <Nav className={'flex-column left-side-nav p-0'} id={'tab-mobile-size'}>
                                                <NavDropdown title={<FormattedMessage id={'nav_third'} />} id="collasible-nav-dropdown">
                                                    <Nav.Item className={'dropdaowns-menu'}>
                                                        <NavDropdown title={<FormattedMessage id={'product_menu_first'} />} id="collasible-nav-dropdown">
                                                            <NavDropdown.Item  eventKey={'napkins_box'}>
                                                                <FormattedMessage id={"napkins_tup"} />
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item eventKey={'napkins_pol'} >
                                                                <FormattedMessage id={"napkins_celafon"} />
                                                            </NavDropdown.Item>
                                                        </NavDropdown>
                                                    </Nav.Item>
                                                    <Nav.Item className={'dropdaowns-menu'}>
                                                        <NavDropdown title={<FormattedMessage id={'product_menu_second'} />} id="collasible-nav-dropdown">
                                                            <NavDropdown.Item  eventKey={'toilet1'} id={'kkkk'}>
                                                                <FormattedMessage id={"shert1"} />
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item  eventKey={'toilet2'}>
                                                                <FormattedMessage id={"shert2"} />
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item eventKey={'toilet3'}>
                                                                <FormattedMessage id={"shert3"} />
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item eventKey={'toilet4'}>
                                                                <FormattedMessage id={"shert4"} />
                                                            </NavDropdown.Item>
                                                        </NavDropdown>
                                                    </Nav.Item>
                                                    <Nav.Item className={'dropdaowns-menu'}>
                                                        <NavDropdown title={<FormattedMessage id={'product_menu_third'} />} id="collasible-nav-dropdown">
                                                            <NavDropdown.Item eventKey={'kitchen2'}>
                                                                <FormattedMessage id={"shert2"} />
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item eventKey={'kitchen3'}>
                                                                <FormattedMessage id={"shert3"} />
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item eventKey={'kitchen4'}>
                                                                <FormattedMessage id={"shert4"} />
                                                            </NavDropdown.Item>
                                                        </NavDropdown>
                                                    </Nav.Item>

                                                    <Nav.Item className={'dropdaowns-menu'} id="collasible-nav-dropdown-other">
                                                        <NavDropdown.Item eventKey={'wetNapkins'} id="collasible-nav-dropdown">
                                                            <FormattedMessage id={'product_menu_forth'} />
                                                        </NavDropdown.Item>
                                                    </Nav.Item>

                                                    <Nav.Item className={'dropdaowns-menu'} id="collasible-nav-dropdown-other">
                                                        <NavDropdown.Item eventKey={'cotton'} id="collasible-nav-dropdown">
                                                            <FormattedMessage id={'product_menu_fifth'} />
                                                        </NavDropdown.Item>
                                                    </Nav.Item>

                                                    <Nav.Item className={'dropdaowns-menu'} id="collasible-nav-dropdown-other">
                                                        <NavDropdown.Item eventKey={'profesional'} id="collasible-nav-dropdown">
                                                            <FormattedMessage id={'product_menu_sixth'} />
                                                        </NavDropdown.Item>
                                                    </Nav.Item>

                                                    <Nav.Item className={'dropdaowns-menu'} id="collasible-nav-dropdown-other">
                                                        <NavDropdown.Item eventKey={'other'} id="collasible-nav-dropdown">
                                                            <FormattedMessage id={'product_menu_seventh'} />
                                                        </NavDropdown.Item>
                                                      
                                                    </Nav.Item>
                                                </NavDropdown>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={9} lg={8} md={8} sm={12} className={'right-tabs-col p-0'}>
                                     <Tab.Content>
                                         <Tab.Pane eventKey={'napkins_box'}>
                                             <NapkinsBox />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'napkins_pol'}>
                                             <NapkinsPol />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'toilet1'}>
                                             <Toilet1 />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'toilet2'}>
                                             <Toilet2 />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'toilet3'}>
                                             <Toilet3 />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'toilet4'}>
                                             <Toilet4 />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'kitchen2'}>
                                             <Kitchen2 />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'kitchen3'}>
                                             <Kitchen3 />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'kitchen4'}>
                                             <Kitchen4 />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'wetNapkins'}>
                                             <WetNapkins />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'cotton'}>
                                             <Cotton />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'other'}>
                                             <Other />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey={'profesional'}>
                                             <Profesional />
                                         </Tab.Pane>
                                     </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </Container>
            </>
        )
    }
}

