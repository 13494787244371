import React ,{useState} from  'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";
import '../../../assets/css/Product/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Modal from "react-modal";
import ReactModal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../../../assets/css/ModalCarousel/style.css";
//import brands img
import SilkLogo from '../../../assets/img/home/logo/SilkLogo.webp';
import PianoLogo from '../../../assets/img/home/logo/piano svg.webp';
import VitaLogo from '../../../assets/img/home/logo/vita svg.webp';

//silk start
import silk0238 from "../../../assets/img/product/WetNapkins/silk/0238 antibacterial 120.jpg";
import silk0245 from "../../../assets/img/product/WetNapkins/silk/0245 universal 120.jpg";
import silk0252 from "../../../assets/img/product/WetNapkins/silk/0252 baby 100.jpg";
import silk0269 from "../../../assets/img/product/WetNapkins/silk/0269 baby 72.jpg";
import silk0276 from "../../../assets/img/product/WetNapkins/silk/0276 antibacterial 80.jpg";
import silk0290_1 from "../../../assets/img/product/WetNapkins/silk/0290 universal 15-1.jpg";
import silk0290_2 from "../../../assets/img/product/WetNapkins/silk/0290 universal 15-2.jpg";
import silk0313_1 from "../../../assets/img/product/WetNapkins/silk/0313 antibacterial 15.jpg";
import silk0313_2 from "../../../assets/img/product/WetNapkins/silk/0313 antibacterial 15 - 2.jpg";
import silk1825_1 from "../../../assets/img/product/WetNapkins/silk/1825 econom 15-1.jpg";
import silk1825_2 from "../../../assets/img/product/WetNapkins/silk/1825 econom 15-2.jpg";
import silk2093 from "../../../assets/img/product/WetNapkins/silk/2093 baby 120.jpg";
import silk2355 from "../../../assets/img/product/WetNapkins/silk/2355 baby 72.jpg";
import silk4105 from "../../../assets/img/product/WetNapkins/silk/4105 baby 100.jpg";
import silk4141_1 from "../../../assets/img/product/WetNapkins/silk/4141 baby 15-1.jpg";
import silk4141_2 from "../../../assets/img/product/WetNapkins/silk/4141 baby 15-2.jpg";
import silk4652 from "../../../assets/img/product/WetNapkins/silk/4652 baby 60.jpg";
import silk5536 from "../../../assets/img/product/WetNapkins/silk/5536 universal 120.jpg";
import silk6749_1 from "../../../assets/img/product/WetNapkins/silk/6749 universal 15 - 1.jpg";
import silk6749_2 from "../../../assets/img/product/WetNapkins/silk/6749 universal 15 - 2.jpg";
import silk7416 from "../../../assets/img/product/WetNapkins/silk/7416 antivirus 60.jpg";
import silk7733 from "../../../assets/img/product/WetNapkins/silk/7733 antivirus 80.jpg";
import silk7757 from "../../../assets/img/product/WetNapkins/silk/7757 universal 72.jpg";
import silk9147_1 from "../../../assets/img/product/WetNapkins/silk/9147 antiseptik 15.jpg";
import silk9147_2 from "../../../assets/img/product/WetNapkins/silk/9147 antivirus 15.jpg";
//silk end
//piano start
  import piano0153 from "../../../assets/img/product/WetNapkins/piano/0153 baby 60.jpg";
  import piano0160 from "../../../assets/img/product/WetNapkins/piano/0160 baby 100.jpg";
  import piano0177_1 from "../../../assets/img/product/WetNapkins/piano/0177 antibacterial 15-1.jpg";
  import piano0177_2 from "../../../assets/img/product/WetNapkins/piano/0177 antibacterial 15-2.jpg";
  import piano0184 from "../../../assets/img/product/WetNapkins/piano/0184 antibacterial 120.jpg";
  import piano0191 from "../../../assets/img/product/WetNapkins/piano/0191 antibacterial 80.jpg";
  import piano0207_1 from "../../../assets/img/product/WetNapkins/piano/0207 15 universal-1.jpg";
  import piano0207_2 from "../../../assets/img/product/WetNapkins/piano/0207 15 universal-2.jpg";
  import piano0221 from "../../../assets/img/product/WetNapkins/piano/0221 72 universal.jpg";
//piano start


// const props = {};

export default class WetNapkins extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showModal: false,
            showModal2: false,

        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseModal2 = this.handleCloseModal2.bind(this);
    }
    handleOpenModal () {
        this.setState({ showModal: true });
    }
    handleOpenModal2 () {
        this.setState({ showModal2: true });
    }
    handleCloseModal () {
        this.setState({ showModal: false });
    }
    handleCloseModal2 () {
        this.setState({ showModal2: false });
    }
    render() {
        return (
            <>
                <Tabs>
                    <Container fluid className={'row-for-tab-lists'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList style={{justifyContent:"center"}}>
                                    <Tab>
                                        <img src={SilkLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                    <Tab>
                                        <img src={PianoLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                    {/*<Tab>*/}
                                    {/*    <img src={VitaLogo} className={'img-fluid'} alt=""/>*/}
                                    {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'row-for-tab-lists-mobile'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList>
                                    <Tab>
                                        <FormattedMessage id={'silk_brand'} />
                                    </Tab>
                                    <Tab>
                                        <FormattedMessage id={'piano_brand'} />
                                    </Tab>
                                    {/*<Tab>*/}
                                    {/*    <FormattedMessage id={'vita_brand'} />*/}
                                    {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'p-0'}>
                        <Row>
                            <Col className={'for_border_bottom'}></Col>
                        </Row>
                    </Container>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0238} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>120 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0238</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0245} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>120 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0245</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0252} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>120 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0245</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0269} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>72 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0269</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0276} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>80 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0276</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0290_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0290</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0290_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0290</p>
                                        </div>
                                    </div>
                                </Col>


                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0313_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0313</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0313_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0313</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1825_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_eco'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>75 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>1825</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1825_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_eco'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>75 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>1825</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk2093} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>120 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>2093</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk2355} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>72 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>2355</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4105} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>100 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>4105</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4141_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>4141</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4141_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>4141</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4652} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>60 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>4652</p>
                                        </div>
                                    </div>
                                </Col>


                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk5536} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>120 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>5536</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk6749_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>6749</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk6749_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>6749</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk7416} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>60 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>7416</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk7733} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>80 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>7733</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk7757} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>72 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>7757</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk9147_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>9147</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk9147_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />
                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 Հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>9147</p>
                                        </div>
                                    </div>
                                </Col>



                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0153} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>60 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0153</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0160} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_child'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>100 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0160</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0177_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0177</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0177_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0177</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0184} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>120 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0184</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0191} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_bakterial'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>80 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0191</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0207_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0207</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0207_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>15 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0207</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0221} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                            <div className="for_desc">
                                                <FormattedMessage id={'wet_universal'} />

                                            </div>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_wet_napkins'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>72 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0221</p>
                                        </div>
                                    </div>
                                </Col>


                            </Row>
                        </Container>
                    </TabPanel>


                </Tabs>
            </>
        )
    }
}
