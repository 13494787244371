import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import App from './App';
import Wrapper from "./components/Wrapper";
import {Provider} from 'react-redux';
import store from './data/store';
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import Admin from './admin/index';
import Register from './admin/screens/Register.jsx'
// import './assets/css/Admin/style.css';
ReactDOM.render(
      <Provider store={store}>
        <Wrapper>
          
             <App />
        </Wrapper>
      </Provider>,
           
  document.getElementById('root')
);
