import React, {Component} from 'react';
import {Container,Row,Col,Carousel} from 'react-bootstrap';
import Slider1 from '../../assets/img/home/Slider1.webp';
import Slider2 from '../../assets/img/home/Slider2.webp';
import Slider3 from '../../assets/img/home/Slider3.webp';
import '../../assets/css/Component/style.css';

export default class CarouselHome extends Component {
    render(){
        return(
            <>
                <Container fluid className={''} id={'section_for_carousel'}>
                    <Row >
                        <Col xl={12} id={'padding_none'}>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        src={Slider1}
                                        className={'img-fluid w-100'}
                                        alt="First slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        src={Slider2}
                                        className={'img-fluid w-100'}
                                        alt="Second slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        src={Slider3}
                                        className={'img-fluid w-100'}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

