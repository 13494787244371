import React from 'react';
import {Container,Row,Col} from "react-bootstrap";
import '../../../assets/css/News/style.css';
import News1 from "../../../assets/img/news/News1.webp";
import News2 from "../../../assets/img/news/News2.webp";
import News3 from "../../../assets/img/news/News3.webp";
import News4 from "../../../assets/img/news/News4.webp";
export default class News extends React.Component{
    render() {
        return (
            <>
                <Container className={'my-5'}>
                    <Row>
                        <Col xl={{span:10,offset:1}}>
                            <Row className={'news-first-section'}>
                                <Col xl={{span:4,offset:0}} lg={{span:4,offset:0}} md={{span:4,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}} className={'news-left-section'}>
                                    <img src={News1} className={'img-fluid w-100'} alt=""/>
                                </Col>
                                <Col xl={{span:8,offset:0}} lg={{span:8,offset:0}} md={{span:8,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}} className={'right-content'}>
                                    <div className={'right-content-text'}>
                                        <div className={'news-title'}>
                                          Մանկական թաց անձեռոցիկներ
                                        </div>
                                        <div className={'news-pre_title'}>
                                            ՇՈՒՏՈՎ PREMIUM դասի Մանկական մաքրող թաց անձեռոցիկներ
                                        </div>

                                        <div className={'news-desc'}>

                                            Սիլկ Սոֆթ -ի անձեռոցիկները հատուկ նրբություն և խտություն ունեն, որով պաշտպանվում է երեխայի մաշկը բակտերիաներից, ունեն հանգստացնող, հակաալերգիկ, թարմացնող էֆեկտ , չեն առաջացնում մաշկի գրգռվածություն և նախատեսված են ծննդյան առաջին իսկ օրվանից օգտագործման համար:
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                            <Row className={'news-first-section'}>
                                <Col xl={{span:4,offset:0}} lg={{span:4,offset:0}} md={{span:4,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}} className={'news-left-section'}>
                                    <img src={News2} className={'img-fluid w-100'} alt=""/>
                                </Col>
                                <Col xl={{span:8,offset:0}} lg={{span:8,offset:0}} md={{span:8,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}} className={'right-content'}>
                                    <div className={'right-content-text'}>
                                        <div className={'news-title'}>
                                            Սեղանի փափուկ անձեռցիկներ նոր փաթեթավորմամբ
                                        </div>
                                        <div className={'news-pre_title'}>
                                            Արդեն վաճառքում են 33սմ*33սմ չափի, 100հատ, 1շերտ բարձրորակ թղթյա անձեռոցիկները նոր փաթեթավորմամբ:
                                        </div>

                                        <div className={'news-desc'}>

                                            Սիլկ Սոֆթ -ի անձեռոցիկները հատուկ նրբություն և խտություն ունեն, որով պաշտպանվում է երեխայի մաշկը բակտերիաներից, ունեն հանգստացնող, հակաալերգիկ, թարմացնող էֆեկտ , չեն առաջացնում մաշկի գրգռվածություն և նախատեսված են ծննդյան առաջին իսկ օրվանից օգտագործման համար:
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                            <Row className={'news-first-section'}>
                                <Col xl={{span:4,offset:0}} lg={{span:4,offset:0}} md={{span:4,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}} className={'news-left-section'}>
                                    <img src={News3} className={'img-fluid w-100'} alt=""/>
                                </Col>
                                <Col xl={{span:8,offset:0}} lg={{span:8,offset:0}} md={{span:8,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}} className={'right-content'}>
                                    <div className={'right-content-text'}>
                                        <div className={'news-title'}>

                                        </div>
                                        <div className={'news-pre_title'}>

                                        </div>

                                        <div className={'news-desc'}>


                                        </div>

                                    </div>
                                </Col>
                            </Row>
                            <Row className={'news-first-section'}>
                                <Col xl={{span:4,offset:0}} lg={{span:4,offset:0}} md={{span:4,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}} className={'news-left-section'}>
                                    <img src={News4} className={'img-fluid w-100'} alt=""/>
                                </Col>
                                <Col xl={{span:8,offset:0}} lg={{span:8,offset:0}} md={{span:8,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}} className={'right-content'}>
                                    <div className={'right-content-text'}>
                                        <div className={'news-title'}>

                                        </div>
                                        <div className={'news-pre_title'}>

                                        </div>

                                        <div className={'news-desc'}>


                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}