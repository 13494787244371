import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Admin from './admin/index';
import Register from './admin/screens/Register';

const Routes = () => {
    return (
        <div>
            <BrowserRouter>

                <Switch>
                    <Route exact path="/SoftAdmin" component={Admin} />
                    <Route exact path="/register" component={Register} />
                </Switch>
            </BrowserRouter>
        </div>
    )
}
export default Routes