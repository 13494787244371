import React ,{useState} from  'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";
import '../../../assets/css/Product/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Modal from "react-modal";
import ReactModal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../../../assets/css/ModalCarousel/style.css";
//import brands img
import SilkLogo from '../../../assets/img/home/logo/SilkLogo.webp';
import PianoLogo from '../../../assets/img/home/logo/piano svg.webp';
import VitaLogo from '../../../assets/img/home/logo/vita svg.webp';
import silk0122 from "../../../assets/img/product/Toilet/other/silk/4shert/0122 1hat 4sh.jpg";
import silk5046 from "../../../assets/img/product/Toilet/other/silk/4shert/5046 4h 4sh.jpg";


// const props = {};

export default class Toilet4 extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showModal: false,
            showModal2: false,

        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseModal2 = this.handleCloseModal2.bind(this);
    }


    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleOpenModal2 () {
        this.setState({ showModal2: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false });
    }

    handleCloseModal2 () {
        this.setState({ showModal2: false });
    }

    render() {
        return (
            <>
                <Tabs>
                    <Container fluid className={'row-for-tab-lists'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList style={{justifyContent:"center"}}>
                                    <Tab>
                                        <img src={SilkLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                    {/*<Tab>*/}
                                    {/*    <img src={PianoLogo} className={'img-fluid'} alt=""/>*/}
                                    {/*</Tab>*/}
                                    {/*<Tab>*/}
                                    {/*    <img src={VitaLogo} className={'img-fluid'} alt=""/>*/}
                                    {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'row-for-tab-lists-mobile'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList>
                                    <Tab>
                                        <FormattedMessage id={'silk_brand'} />
                                    </Tab>
                                    {/*<Tab>*/}
                                    {/*    <FormattedMessage id={'piano_brand'} />*/}
                                    {/*</Tab>*/}
                                    {/*<Tab>*/}
                                    {/*    <FormattedMessage id={'vita_brand'} />*/}
                                    {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'p-0'}>
                        <Row>
                            <Col className={'for_border_bottom'}></Col>
                        </Row>
                    </Container>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0122} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_toilet'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>1 հատ, 4 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0122</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk5046} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'product_toilet'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>4 հատ, 4 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>5046</p>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    </TabPanel>
                </Tabs>
            </>
        )
    }
}
