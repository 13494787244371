import React , {Component} from 'react';
import {Container,Row,Col,Carousel,} from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/Home/style.css';
import {Link} from 'react-router-dom';
// import partner1 from "../../assets/img/home/partImg1.webp";
import partner1 from "../../assets/img/partners/partner1.jpg";
import partner2 from "../../assets/img/partners/partner2.jpg";
import partner3 from "../../assets/img/partners/partner3.jpg";
import partner4 from "../../assets/img/partners/partner4.png";
import partner5 from "../../assets/img/partners/partner5.jpg";
import partner6 from "../../assets/img/partners/partner6.jpg";
import partner7 from "../../assets/img/partners/partner7.jpg";
import partner8 from "../../assets/img/partners/partner8.jpg";
import partner9 from "../../assets/img/partners/partner9.jpg";
import partner10 from "../../assets/img/partners/partner10.jpg";
import partner11 from "../../assets/img/partners/partner11.jpg";
import partner12 from "../../assets/img/partners/partner12.jpg";
import partner13 from "../../assets/img/partners/partner13.jpg";
import partner14 from "../../assets/img/partners/partner14.png";
import partner15 from "../../assets/img/partners/partner15.jpg";
import partner16 from "../../assets/img/partners/partner16.jpg";
import partner17 from "../../assets/img/partners/partner17.jpg";
import partner18 from "../../assets/img/partners/partner18.jpg";

const config = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay:true,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [{
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
        }
    }]
};
const products = [
    {
        img:partner1,
        title: 'Dolore magna',
        text: 'Lorem ipsum dolor sit amet elit.'
    },
    {
        img: partner2,
        title: 'Eget est lorem',
        text: 'Lorem Ipsum adipiscing elit ipsum.'
    },
    {
        img: partner3,
        title: 'Tempus imperdiet',
        text: 'Orci porta non pulvinar neque laoreet.'
    },
    {
        img:partner4,
        title: 'Mattis rhoncus',
        text: 'Bibendum neque egestas congue quisque.'
    },
    {
        img: partner5,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner6,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner7,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner8,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner9,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner10,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner11,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner12,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner13,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner14,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner15,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner16,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner17,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    },
    {
        img: partner18,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    }

]
export default class SliderPart extends Component {
    render(){
        return (
            <Slider {...config}>
                {products.map((x, i) => {
                    return <div key="{i}"  className="img-card">
                        <img className="img img-fluid" src={x.img} alt={x.title} />

                    </div>
                })}
            </Slider>
        )
    }
}
