import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../assets/css/Home/style.css";
import home_about from "../../../assets/img/home/home_about.jpg";
import HomeSlider from "../HomeSlider";
import RepSlider from "../SliderRep";
import SliderPart from "../SliderPar";
import aboutIcon1 from "../../../assets/img/home/about_icon_first.webp";
import aboutIcon2 from "../../../assets/img/home/Armenia_template 2.webp";
import aboutIcon3 from "../../../assets/img/home/about_icon_third.webp";

import SilkBrand from "../../../assets/img/home/logo/SilkLogo.webp";
import VitaBrand from "../../../assets/img/home/logo/vita svg.webp";
import PianoBrand from "../../../assets/img/home/logo/piano svg.webp";

import home_product1 from "../../../assets/img/home/newProduct1.webp";
import home_product2 from "../../../assets/img/home/newproduct2.webp";
import home_product3 from "../../../assets/img/home/newproduct3.webp";
import home_product4 from "../../../assets/img/home/newproduct4.webp";
import News1 from "../../../assets/img/home/News1.webp";
import News2 from "../../../assets/img/home/News2.webp";
import News3 from "../../../assets/img/home/News3.webp";
import News4 from "../../../assets/img/home/News4.webp";
export default class Home extends React.Component {
  render() {
    return (
      <>
        <Container fluid id={"home_page_carousel_section"}>
          <HomeSlider />
        </Container>
        <Container className={"home_page_about_section"}>
          <Row className={"mobile-size-bgc"}>
            <Col
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              xs={{ span: 12, offset: 0 }}
            >
              <div className={"home_about_text"}>
                <p className={"home_about_title"}>
                  <FormattedMessage id={"nav_second"} />
                </p>
                <p className={"home_about_desc"}>
                  «Սոֆթ Թրեյդ» ՍՊԸ հիմնադրվել է 2010 թվականին և այսօր
                  հանդիսանում է Հայաստանի թղթե հիգիենայի ոլորտում առաջատար
                  ձեռնարկություններից մեկը: Կազմակերպված աշխատանքի շնորհիվ սեղմ
                  ժամանակահատվածում ընկերությունն իր հաստատուն տեղն է զբաղեցրել
                  հայկական շուկայում` սպառողին առաջարկելով բարձրորակ,
                  էկոլոգիապես մաքուր և մատչելի հայկական արտադրանք։
                </p>
              </div>
            </Col>
            <Col
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              sm={{ span: 8, offset: 2 }}
              xs={{ span: 12, offset: 0 }}
              className={"right_content"}
            >
              <img src={home_about} className={"img-fluid"} alt="" />
            </Col>
          </Row>
        </Container>
        <Container className={"flex_end_small_contents"}>
          <Row>
            <Col
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              md={{ span: 6, offset: 3 }}
              className={"mt-2"}
            >
              <div className={"icon_contents"}>
                <div className={"for_circle_icon"}>
                  <img src={aboutIcon1} className={"img-fluid"} alt="" />
                </div>
                <div className={"for_icon_text"}>
                  <p className={"for_icon_title"}>Բարձրորակ հումք</p>
                  <p className={"for_icon_desc"}>
                    Մեր արտադրանքի համար օգտագործվում է միայն բարձրորակ,
                    էկոլոգիապես մաքուր 100% ցելյուլոզա, որն ունի FSC վկայական։
                  </p>
                </div>
              </div>
            </Col>
            <Col
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              md={{ span: 6, offset: 3 }}
              className={"mt-2"}
            >
              <div className={"icon_contents icon_contents_second"}>
                <div className={"for_circle_icon"}>
                  <img src={aboutIcon2} className={"img-fluid"} alt="" />
                </div>
                <div className={"for_icon_text"}>
                  <p className={"for_icon_title"}>Հայկական արտադրանք</p>
                  <p className={"for_icon_desc"}>
                    Մեր հաճախորդներին առաջարկում ենք տնտեսական ապրանքների լայն
                    տեսականի՝ Արտադրված Հայաստանում։
                  </p>
                </div>
              </div>
            </Col>
            <Col
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              md={{ span: 6, offset: 3 }}
              className={"mt-2"}
            >
              <div className={"icon_contents"}>
                <div className={"for_circle_icon"}>
                  <img src={aboutIcon3} className={"img-fluid"} alt="" />
                </div>
                <div className={"for_icon_text"}>
                  <p className={"for_icon_title"}>Սպասարկման բարձր որակ</p>
                  <p className={"for_icon_desc"}>
                    95% Պատվերների առաքում 24 ժամվա ընթացքում Հայաստանի
                    Հանրապետության և Արցախի ամբողջ տարածքում։
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className={"section_for_brands_background"}>
          <Row className={"mobile-size-bgc"}>
            <Container className={"section_for_brands"}>
              <Row>
                <Col className={"brand_text"} sm={{ span: 8, offset: 2 }}>
                  <FormattedMessage id={"link_brands"} />
                </Col>
                <Col
                  className={"brand_small_text"}
                  sm={{ span: 6, offset: 3 }}
                  xs={{ span: 12, offset: 0 }}
                >
                  Արդեն տասը տարի է, ինչ մեր ապրանքային նշանները գրավել են
                  առաջատար և իրենց ուրույն տեղը հայկական շուկայում՝ դառնալով
                  սիրելի և վստահելի արտադրանք սպառողների համար։
                </Col>
              </Row>
              <Row className={"brand_image_row"}>
                <Col xl={{ span: 8, offset: 2 }}>
                  <Row>
                    <Col
                      xl={{ span: 4, offset: 0 }}
                      lg={{ span: 4, offset: 0 }}
                      md={{ span: 4, offset: 0 }}
                      sm={{ span: 6, offset: 3 }}
                      xs={{ span: 12, offset: 0 }}
                      className={"col_for_brand"}
                    >
                      <div className={"for_brand_logo"}>
                        <div className={"for_brand_logo_img first-img"}>
                          <img
                            src={SilkBrand}
                            className={"img-fluid"}
                            alt={""}
                          />
                        </div>
                        <div className={"for_brand_logo_text"}>
                          <p className={"for_brand_logo_title"}>Սիլկ Սոֆթ</p>
                          <p className={"for_brand_logo_desc"}>
                            Սիլկ Սոֆթը ապրանքանիշը՝ «Սոֆթ Թրեյդ» ՍՊԸ-ի առաջատար
                            ապրանքանիշն է և Հայաստանի թղթե հիգիենիկ արտադրանքի
                            շուկայում առաջատարը։
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col
                      xl={{ span: 4, offset: 0 }}
                      lg={{ span: 4, offset: 0 }}
                      md={{ span: 4, offset: 0 }}
                      sm={{ span: 6, offset: 3 }}
                      xs={{ span: 12, offset: 0 }}
                      className={"col_for_brand"}
                    >
                      <div className={"for_brand_logo"}>
                        <div className={"for_brand_logo_img first-img"}>
                          <img
                            src={PianoBrand}
                            className={"img-fluid"}
                            alt={""}
                          />
                        </div>
                        <div className={"for_brand_logo_text"}>
                          <p className={"for_brand_logo_title"}>Պիանո</p>
                          <p className={"for_brand_logo_desc"}>
                            Պիանոն հանդիսանում է արագ զարգացող հայկական
                            ապրանքանիշ, որի հիմքում ընկած է բարձր որակի և
                            մատչելի գնի համադրության սկզբունքը։
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col
                      xl={{ span: 4, offset: 0 }}
                      lg={{ span: 4, offset: 0 }}
                      md={{ span: 4, offset: 0 }}
                      sm={{ span: 6, offset: 3 }}
                      xs={{ span: 12, offset: 0 }}
                      className={"col_for_brand"}
                    >
                      <div className={"for_brand_logo"}>
                        <div className={"for_brand_logo_img"}>
                          <img
                            src={VitaBrand}
                            className={"img-fluid first-img"}
                            alt={""}
                          />
                        </div>
                        <div className={"for_brand_logo_text"}>
                          <p className={"for_brand_logo_title"}>Վիտա</p>
                          <p className={"for_brand_logo_desc"}>
                            Վիտան լատիներենից թարգմանաբար նշանակում է կյանք։Այս
                            ապրանքանիշը` բարձրորակ և էկոլոգապես մաքուր արտադրանք
                            է մրցունակ գնով։
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={""}>
                    <Link
                      to="/brands"
                      id={"link_for_see_more_small"}
                      style={{ textDecoration: "none" }}
                    >
                      <FormattedMessage id={"read_more"} />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>

        <Container fluid id={"section_for_product"}>
          <Container>
            <Row id={"row_for_text"}>
              <Col xl={12}>
                <div className={"for_product_big_text"}>
                  <FormattedMessage id={"nav_third"} />
                </div>
              </Col>
              <Col
                xl={{ span: 6, offset: 3 }}
                lg={{ span: 6, offset: 3 }}
                md={{ span: 8, offset: 2 }}
                className={""}
              >
                <div className={"for_product_small_text"}>
                  Մենք առաջարկում ենք բարձրորակ հայկական արտադրության <br />
                  թղթե - հիգիենիկ արտադրանք՝ արտադրված էկոլոգիապես մաքուր
                  հումքից, օգտագործելով նորագույն տեխնոլոգիական լուծումներ:
                </div>
              </Col>
            </Row>
            <Row className={"for_img_row"}>
              <Col
                xl={{ span: 3, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                xs={{ span: 10, offset: 1 }}
                className={"div_for_png mt-4"}
              >
                <div className={"for_product_image_hover"}>
                  <div className={"relative_div_img"}>
                    <img src={home_product1} alt={""} className={"img-fluid"} />
                    <div className={"absolute_div_text"}>
                      <FormattedMessage id={"product_napkins"} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xl={{ span: 3, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                xs={{ span: 10, offset: 1 }}
                className={"div_for_png mt-4"}
              >
                <div className={"for_product_image_hover"}>
                  <div className={"relative_div_img"}>
                    <img src={home_product2} alt={""} className={"img-fluid"} />
                    <div className={"absolute_div_text"}>
                      <FormattedMessage id={"product_kitchen"} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xl={{ span: 3, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                xs={{ span: 10, offset: 1 }}
                className={"div_for_png mt-4"}
              >
                <div className={"for_product_image_hover"}>
                  <div className={"relative_div_img"}>
                    <img src={home_product3} alt={""} className={"img-fluid"} />
                    <div className={"absolute_div_text"}>
                      <FormattedMessage id={"product_toilet"} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xl={{ span: 3, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                xs={{ span: 10, offset: 1 }}
                className={"div_for_png mt-4"}
              >
                <div className={"for_product_image_hover"}>
                  <div className={"relative_div_img"}>
                    <img src={home_product4} alt={""} className={"img-fluid"} />
                    <div className={"absolute_div_text"}>
                      <FormattedMessage id={"product_wet_napkins"} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12} className={"for_see_more_Link"}>
                <Link to="/news" style={{ textDecoration: "none" }}>
                  <FormattedMessage id={"online_shop"} />
                </Link>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container className={"setion_for_representation"}>
          <Row className={"for_representation_title"}>
            <Col xl={12}>
              <FormattedMessage id={"nav_fifth"} />
            </Col>
          </Row>
          <Row className={"row_for_carousel"}>
            <Col xl={{ span: 8, offset: 2 }} className={""}>
              <RepSlider />
            </Col>
          </Row>
        </Container>
        <Container fluid className={"setion_for_news"}>
          <Container>
            <Row className={"for_news_title"}>
              <Col xl={12}>
                <FormattedMessage id={"nav_seventh"} />
              </Col>
            </Row>
            <Row className={"row_for_news"}>
              <Col
                xl={{ span: 3, offset: 0 }}
                lg={{ span: 3, offset: 0 }}
                md={{ span: 3, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                className={"hover-card"}
              >
                <div className={"card"}>
                  <img src={News1} alt={""} className={"img-fluid"} />
                  <div className="info">
                    <p className={"title"}>ՆՈՐՈՒՅԹ</p>
                    {/*<p className={'description'}>*/}
                    {/*    Նոր, քառաշերտ*/}
                    {/*    զուգարանի թղթերը...*/}
                    {/*</p>*/}
                    <Link to="/news">
                      <FormattedMessage id={"read_more"} />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col
                xl={{ span: 3, offset: 0 }}
                lg={{ span: 3, offset: 0 }}
                md={{ span: 3, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                className={"hover-card card-none"}
              >
                <div className={"card"}>
                  <img src={News2} alt={""} className={"img-fluid"} />
                  <div className="info">
                    <p className={"title"}>ՆՈՐՈՒՅԹ</p>
                    {/*<p className={'description'}>*/}
                    {/*    Նոր, քառաշերտ*/}
                    {/*    զուգարանի թղթերը...*/}
                    {/*</p>*/}
                    <Link to="/news">
                      <FormattedMessage id={"read_more"} />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col
                xl={{ span: 3, offset: 0 }}
                lg={{ span: 3, offset: 0 }}
                md={{ span: 3, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                className={"hover-card card-none"}
              >
                <div className={"card"}>
                  <img src={News3} alt={""} className={"img-fluid"} />
                  <div className="info">
                    <p className={"title"}>ՆՈՐՈՒՅԹ</p>
                    {/*<p className={'description'}>*/}
                    {/*    Նոր, քառաշերտ*/}
                    {/*    զուգարանի թղթերը...*/}
                    {/*</p>*/}
                    <Link to="/news">
                      <FormattedMessage id={"read_more"} />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col
                xl={{ span: 3, offset: 0 }}
                lg={{ span: 3, offset: 0 }}
                md={{ span: 3, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                xs={{ span: 10, offset: 1 }}
                className={"hover-card card-none"}
              >
                <div className={"card"}>
                  <img src={News4} alt={""} className={"img-fluid"} />
                  <div className="info">
                    <p className={"title"}>ՆՈՐՈՒՅԹ</p>
                    <p className={"description"}></p>
                    <Link to="/news">
                      <FormattedMessage id={"read_more"} />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={""}>
                  <Link
                    to="/news"
                    id={"link_for_see_more_small"}
                    style={{ textDecoration: "none" }}
                  >
                    <FormattedMessage id={"read_more"} />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className={"section_for_partners"}>
          <Container>
            <Row className={"partners_title"}>
              <Col xl={12}>
                <FormattedMessage id={"nav_forth"} />
              </Col>
            </Row>
            <Row className={"row_for_carousele"}>
              <Col xl={12}>
                <SliderPart />
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
}
