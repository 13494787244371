import React ,{Component,useState} from 'react';
import {Container,Row,Col,Form,Button,} from 'react-bootstrap';
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import '../../../assets/css/Career/style.css';
import carer1 from '../../../assets/img/career/carer1.webp';
import carer2 from '../../../assets/img/career/carer2.webp';
import carer3 from '../../../assets/img/career/carer3.webp';
import carer4 from '../../../assets/img/career/carer4.webp';
import carer5 from '../../../assets/img/career/carer5.webp';
import carer6 from '../../../assets/img/career/carer6.webp';
import carer7 from '../../../assets/img/career/carer7.webp';
import carer8 from '../../../assets/img/career/carer8.webp';
import {FormattedMessage} from "react-intl";
import emailjs from 'emailjs-com';
// function sendEmail (e) {
//     e.preventDefault();
//
//     emailjs.sendForm('soft_trade', 'template_qxuvwfz', e.target, 'user_Dv5eODlX9SLfB9YK94wjk')
//         .then((result) => {
//             console.log(result.text);
//         }, (error) => {
//             console.log(error.text);
//         });
//         e.target.reset();
// }



function sendEmail (e) {
    e.preventDefault();

    emailjs.sendForm('soft_trade', 'soft-career', e.target, 'user_ysNgUk8Z8rLmyXZF671FA')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
}

export default class Career extends React.Component {
    state = {
        isOpen: false,
        isOpen1: false,
        isOpen2: false,
        isOpen3: false,
        isOpen4: false,
        isOpen5: false,
        isOpen6: false,
        isOpen7: false,
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };
    toggleModal1 = () => {
        this.setState(prevState => ({
            isOpen1: !prevState.isOpen1,
        }));
    };
    toggleModal2 = () => {
        this.setState(prevState => ({
            isOpen2: !prevState.isOpen2,
        }));
    };
    toggleModal3 = () => {
        this.setState(prevState => ({
            isOpen3: !prevState.isOpen3,
        }));
    };
    toggleModal4 = () => {
        this.setState(prevState => ({
            isOpen4: !prevState.isOpen4,
        }));
    };
    toggleModal5 = () => {
        this.setState(prevState => ({
            isOpen5: !prevState.isOpen5,
        }));
    };
    toggleModal6 = () => {
        this.setState(prevState => ({
            isOpen6: !prevState.isOpen6,
        }));
    };
    toggleModal7 = () => {
        this.setState(prevState => ({
            isOpen7: !prevState.isOpen7,
        }));
    };
    render() {
        const modalStyles = {
            overlay: {
                backgroundColor: 'rgba(0,0,0,.6)',
            },
        };
        return(
            <>
                <Container className={'first_career_section'}>
                    <Row>
                        <Col xl={{span:12,offset:0}} className={'career_first_title'}>
                            <FormattedMessage id={'nav_sixth'} />
                        </Col>
                        <Col xl={{span:8,offset:2}} className={'career_first_desc'}>
                            «Սոթֆ Թրեյդ» ՍՊԸ-ն առաջարկում է աշխատանք Հայաստանում իր ոլորտի մեջ առաջատար և կայուն զարգացող ընկերությունում։  Մենք հպարտ ենք մեր աշխատակիցներով,  գնահատում ենք թիմային աշխատանքը, նորարական և պրոֆեսիոնալ մոտեցումը ։
                        </Col>
                        <Col xl={{span:8,offset:2}} className={'career_first_desc mt-3'}>
                            Եթե դուք ունեք ցանկություն միանալու մեր պրեֆեսիոնալ թիմին և ունենալու ձեր ներդրումը մեր ընդհանուր գործի զարգացման մեջ, ապա սպասում ենք ձեզ։
                        </Col>
                        <Col xl={{span:8,offset:2}} className={'career_first_desc mt-3'}>
                            Միասին մենք կարող ենք անել ավելին:
                        </Col>
                    </Row>
                </Container>
                <Container className={'second_career_section'}>
                    <Row>
                        <Col xl={12} className={'second_career_section_title'}>
                            Առկա հաստիքներ
                        </Col>
                    </Row>
                    <Row className={'section_for_jobs'}>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{ span:4,offset:0}} sm={{span:5,offset:1}} xs={{span:6,offset:0}} className={'mt-5 padding-top-section'}>
                            <div className={'div_for_jobs'}>
                                <div className={'for_carer_desc'}>
                                    <FormattedMessage id={'career_desc1'} />
                                </div>
                                <div className={'for_carer_img'}>
                                    <img src={carer1} className={'img-fluid'} alt=""/>
                                </div>

                                <div className={'for_carer_btn'}>
                                    {/*<input type="button" value="Open" className={'button_for_apply'} onClick={() => this.handleModal()} />*/}
                                    <button type="button"  className={'button_for_apply'} onClick={this.toggleModal}>
                                        <FormattedMessage id={'button_value'} />
                                    </button>
                                </div>
                            </div>
                            <CSSTransition
                                in={this.state.isOpen}
                                timeout={300}
                                classNames="dialog"
                            >
                            <Modal
                                closeTimeoutMS={500}
                                isOpen={this.state.isOpen}
                                style={modalStyles}
                            >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal} style={{float:'right'}} >X</button>
                                    </div>


                                    <Container className={'row_for_modal_title'}>
                                        <Row >
                                            <Col xl={{span:12,offset:0}} className={'modal_title text-center'}>
                                                Ողջույն, <br/>
                                                կարող եք լրացնել հայտը, կամ կցել րեզյումե
                                            </Col>
                                        </Row>
                                        <Row className={'row_for_form'}>
                                            <Col xl={{span:10,offset:1}} className={'padding_none_input'}>
                                                <Form onSubmit={sendEmail}>
                                                    <Form.Group>
                                                        <Form.Label for='name_lastname'>Անուն, ազգանուն</Form.Label>
                                                        <Form.Control type='text' id={'name_lastname'} placeholder={'Անի Ադամյան'} name={'name-lastname'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_phone'>Հեռախոսահամար</Form.Label>
                                                        <Form.Control type='number' id={'for_phone'} placeholder={'+374 00 00 00 00'} name={'phone-number'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Կրթություն</Form.Label>
                                                        <Form.Control as="select" id={'for_education'} name={'education'}>
                                                            <option value="Միջնակարգ">Միջնակարգ</option>
                                                            <option value="Բակալավր">Բակալավր</option>
                                                            <option value="Մագիստրոս">Մագիստրոս</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_staff'>Հաստիք</Form.Label>
                                                        <Form.Control type='text' id={'for_staff'} value={'Վարորդ-Առաքիչ'} name={'staff'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label for='for_email'>Էլ. հասցե</Form.Label>
                                                        <Form.Control type='email' id={'for_email'} placeholder={'example@mail.ru'} name={'email_address'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label htmlFor={'description'}>Նկարագրություն</Form.Label>
                                                        <Form.Control as="textarea" id={'description'} name={'description'} placeholder="Ողջույն..." rows={3} />
                                                    </Form.Group>
                                                    <div className={'for_sbm_and_cv'}>
                                                        <input type={'submit'} className={'right_btn_cbm'} value={'Ուղարկել'} />
                                                        {/*<input type={'submit'} className={'left_btn_cv'} value={'Ուղարկել'} />*/}
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                            </Modal>
                            </CSSTransition>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{ span:4,offset:0}} sm={{span:5,offset:1}} xs={{span:6,offset:0}} className={'mt-5 padding-top-section'}>
                            <div className={'div_for_jobs'}>
                                <div className={'for_carer_desc'}>
                                    <FormattedMessage id={'career_desc2'} />
                                </div>
                                <div className={'for_carer_img'}>
                                    <img src={carer2} className={'img-fluid'} alt=""/>
                                </div>

                                <div className={'for_carer_btn'}>
                                    <button type="button"  className={'button_for_apply'} onClick={this.toggleModal1}>
                                        <FormattedMessage id={'button_value'} />
                                    </button>

                                </div>
                            </div>
                            <CSSTransition
                                in={this.state.isOpen}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen1}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal1} style={{float:'right'}} >X</button>
                                    </div>


                                    <Container className={'row_for_modal_title'}>
                                        <Row >
                                            <Col xl={{span:12,offset:0}} className={'modal_title text-center'}>
                                                Ողջույն, <br/>
                                                կարող եք լրացնել հայտը, կամ կցել րեզյումե
                                            </Col>
                                        </Row>
                                        <Row className={'row_for_form'}>
                                            <Col xl={{span:10,offset:1}} className={'padding_none_input'}>
                                                <Form onSubmit={sendEmail}>
                                                    <Form.Group>
                                                        <Form.Label for='name_lastname'>Անուն, ազգանուն</Form.Label>
                                                        <Form.Control type='text' id={'name_lastname'} placeholder={'Անի Ադամյան'} name={'name-lastname'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_phone'>Հեռախոսահամար</Form.Label>
                                                        <Form.Control type='number' id={'for_phone'} placeholder={'+374 00 00 00 00'} name={'phone-number'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Կրթություն</Form.Label>
                                                        <Form.Control as="select" id={'for_education'} name={'education'}>
                                                            <option value="Միջնակարգ">Միջնակարգ</option>
                                                            <option value="Բակալավր">Բակալավր</option>
                                                            <option value="Մագիստրոս">Մագիստրոս</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_staff'>Հաստիք</Form.Label>
                                                        <Form.Control type='text' id={'for_staff'} value={'Փաթեթավորող'} name={'staff'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label for='for_email'>Էլ. հասցե</Form.Label>
                                                        <Form.Control type='email' id={'for_email'} placeholder={'example@mail.ru'} name={'email_address'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label htmlFor={'description'}>Նկարագրություն</Form.Label>
                                                        <Form.Control as="textarea" id={'description'} name={'description'} placeholder="Ողջույն..." rows={3} />
                                                    </Form.Group>
                                                    <div className={'for_sbm_and_cv'}>
                                                        <input type={'submit'} className={'right_btn_cbm'} value={'Ուղարկել'} />
                                                        {/*<input type={'submit'} className={'left_btn_cv'} value={'Ուղարկել'} />*/}
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{ span:4,offset:0}} sm={{span:5,offset:1}} xs={{span:6,offset:0}} className={'mt-5'}>
                            <div className={'div_for_jobs'}>
                                <div className={'for_carer_desc'}>
                                    <FormattedMessage id={'career_desc3'} />
                                </div>
                                <div className={'for_carer_img'}>
                                    <img src={carer3} className={'img-fluid'} alt=""/>
                                </div>

                                <div className={'for_carer_btn'}>
                                    <button type="button"  className={'button_for_apply'} onClick={this.toggleModal2}>
                                        <FormattedMessage id={'button_value'} />
                                    </button>
                                </div>
                            </div>
                            <CSSTransition
                                in={this.state.isOpen}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen2}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal2} style={{float:'right'}} >X</button>
                                    </div>


                                    <Container className={'row_for_modal_title'}>
                                        <Row >
                                            <Col xl={{span:12,offset:0}} className={'modal_title text-center'}>
                                                Ողջույն, <br/>
                                                կարող եք լրացնել հայտը, կամ կցել րեզյումե
                                            </Col>
                                        </Row>
                                        <Row className={'row_for_form'}>
                                            <Col xl={{span:10,offset:1}} className={'padding_none_input'}>
                                                <Form onSubmit={sendEmail}>
                                                    <Form.Group>
                                                        <Form.Label for='name_lastname'>Անուն, ազգանուն</Form.Label>
                                                        <Form.Control type='text' id={'name_lastname'} placeholder={'Անի Ադամյան'} name={'name-lastname'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_phone'>Հեռախոսահամար</Form.Label>
                                                        <Form.Control type='number' id={'for_phone'} placeholder={'+374 00 00 00 00'} name={'phone-number'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Կրթություն</Form.Label>
                                                        <Form.Control as="select" id={'for_education'} name={'education'}>
                                                            <option value="Միջնակարգ">Միջնակարգ</option>
                                                            <option value="Բակալավր">Բակալավր</option>
                                                            <option value="Մագիստրոս">Մագիստրոս</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_staff'>Հաստիք</Form.Label>
                                                        <Form.Control type='text' id={'for_staff'} value={'Բանվոր'} name={'staff'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label for='for_email'>Էլ. հասցե</Form.Label>
                                                        <Form.Control type='email' id={'for_email'} placeholder={'example@mail.ru'} name={'email_address'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label htmlFor={'description'}>Նկարագրություն</Form.Label>
                                                        <Form.Control as="textarea" id={'description'} name={'description'} placeholder="Ողջույն..." rows={3} />
                                                    </Form.Group>
                                                    <div className={'for_sbm_and_cv'}>
                                                        <input type={'submit'} className={'right_btn_cbm'} value={'Ուղարկել'} />
                                                        {/*<input type={'submit'} className={'left_btn_cv'} value={'Ուղարկել'} />*/}
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{ span:4,offset:0}} sm={{span:5,offset:1}} xs={{span:6,offset:0}} className={'mt-5'}>
                            <div className={'div_for_jobs'}>
                                <div className={'for_carer_desc'}>
                                    <FormattedMessage id={'career_desc4'} />
                                </div>
                                <div className={'for_carer_img'}>
                                    <img src={carer4} className={'img-fluid'} alt=""/>
                                </div>

                                <div className={'for_carer_btn'}>
                                    <button type="button"  className={'button_for_apply'}  onClick={this.toggleModal3}>
                                        <FormattedMessage id={'button_value'} />
                                    </button>
                                </div>
                            </div>
                            <CSSTransition
                                in={this.state.isOpen3}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen3}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal3} style={{float:'right'}} >X</button>
                                    </div>


                                    <Container className={'row_for_modal_title'}>
                                        <Row >
                                            <Col xl={{span:12,offset:0}} className={'modal_title text-center'}>
                                                Ողջույն, <br/>
                                                կարող եք լրացնել հայտը, կամ կցել րեզյումե
                                            </Col>
                                        </Row>
                                        <Row className={'row_for_form'}>
                                            <Col xl={{span:10,offset:1}} className={'padding_none_input'}>
                                                <Form onSubmit={sendEmail}>
                                                    <Form.Group>
                                                        <Form.Label for='name_lastname'>Անուն, ազգանուն</Form.Label>
                                                        <Form.Control type='text' id={'name_lastname'} placeholder={'Անի Ադամյան'} name={'name-lastname'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_phone'>Հեռախոսահամար</Form.Label>
                                                        <Form.Control type='number' id={'for_phone'} placeholder={'+374 00 00 00 00'} name={'phone-number'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Կրթություն</Form.Label>
                                                        <Form.Control as="select" id={'for_education'} name={'education'}>
                                                            <option value="Միջնակարգ">Միջնակարգ</option>
                                                            <option value="Բակալավր">Բակալավր</option>
                                                            <option value="Մագիստրոս">Մագիստրոս</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_staff'>Հաստիք</Form.Label>
                                                        <Form.Control type='text' id={'for_staff'} value={'Պահեստապետ'} name={'staff'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label for='for_email'>Էլ. հասցե</Form.Label>
                                                        <Form.Control type='email' id={'for_email'} placeholder={'example@mail.ru'} name={'email_address'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label htmlFor={'description'}>Նկարագրություն</Form.Label>
                                                        <Form.Control as="textarea" id={'description'} name={'description'} placeholder="Ողջույն..." rows={3} />
                                                    </Form.Group>
                                                    <div className={'for_sbm_and_cv'}>
                                                        <input type={'submit'} className={'right_btn_cbm'} value={'Ուղարկել'} />
                                                        {/*<input type={'submit'} className={'left_btn_cv'} value={'Ուղարկել'} />*/}
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{ span:4,offset:0}} sm={{span:5,offset:1}} xs={{span:6,offset:0}} className={'mt-5'}>
                            <div className={'div_for_jobs'}>
                                <div className={'for_carer_desc'}>
                                    <FormattedMessage id={'career_desc5'} />
                                </div>
                                <div className={'for_carer_img'}>
                                    <img src={carer5} className={'img-fluid'} alt=""/>
                                </div>

                                <div className={'for_carer_btn'}>
                                    <button type="button"  className={'button_for_apply'} onClick={this.toggleModal4}>
                                        <FormattedMessage id={'button_value'} />
                                    </button>
                                </div>
                            </div>
                            <CSSTransition
                                in={this.state.isOpen4}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen4}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal4} style={{float:'right'}} >X</button>
                                    </div>


                                    <Container className={'row_for_modal_title'}>
                                        <Row >
                                            <Col xl={{span:12,offset:0}} className={'modal_title text-center'}>
                                                Ողջույն, <br/>
                                                կարող եք լրացնել հայտը, կամ կցել րեզյումե
                                            </Col>
                                        </Row>
                                        <Row className={'row_for_form'}>
                                            <Col xl={{span:10,offset:1}} className={'padding_none_input'}>
                                                <Form onSubmit={sendEmail}>
                                                    <Form.Group>
                                                        <Form.Label for='name_lastname'>Անուն, ազգանուն</Form.Label>
                                                        <Form.Control type='text' id={'name_lastname'} placeholder={'Անի Ադամյան'} name={'name-lastname'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_phone'>Հեռախոսահամար</Form.Label>
                                                        <Form.Control type='number' id={'for_phone'} placeholder={'+374 00 00 00 00'} name={'phone-number'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Կրթություն</Form.Label>
                                                        <Form.Control as="select" id={'for_education'} name={'education'}>
                                                            <option value="Միջնակարգ">Միջնակարգ</option>
                                                            <option value="Բակալավր">Բակալավր</option>
                                                            <option value="Մագիստրոս">Մագիստրոս</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_staff'>Հաստիք</Form.Label>
                                                        <Form.Control type='text' id={'for_staff'} value={'Հաստոցի մեխանիկ'} name={'staff'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label for='for_email'>Էլ. հասցե</Form.Label>
                                                        <Form.Control type='email' id={'for_email'} placeholder={'example@mail.ru'} name={'email_address'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label htmlFor={'description'}>Նկարագրություն</Form.Label>
                                                        <Form.Control as="textarea" id={'description'} name={'description'} placeholder="Ողջույն..." rows={3} />
                                                    </Form.Group>
                                                    <div className={'for_sbm_and_cv'}>
                                                        <input type={'submit'} className={'right_btn_cbm'} value={'Ուղարկել'} />
                                                        {/*<input type={'submit'} className={'left_btn_cv'} value={'Ուղարկել'} />*/}
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{ span:4,offset:0}} sm={{span:5,offset:1}} xs={{span:6,offset:0}} className={'mt-5'}>
                            <div className={'div_for_jobs'}>
                                <div className={'for_carer_desc'}>
                                    <FormattedMessage id={'career_desc6'} />
                                </div>
                                <div className={'for_carer_img'}>
                                    <img src={carer6} className={'img-fluid'} alt=""/>
                                </div>

                                <div className={'for_carer_btn'}>
                                    <button type="button"  className={'button_for_apply'} onClick={this.toggleModal5}>
                                        <FormattedMessage id={'button_value'} />
                                    </button>
                                </div>
                            </div>
                            <CSSTransition
                                in={this.state.isOpen5}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen5}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal5} style={{float:'right'}} >X</button>
                                    </div>


                                    <Container className={'row_for_modal_title'}>
                                        <Row >
                                            <Col xl={{span:12,offset:0}} className={'modal_title text-center'}>
                                                Ողջույն, <br/>
                                                կարող եք լրացնել հայտը, կամ կցել րեզյումե
                                            </Col>
                                        </Row>
                                        <Row className={'row_for_form'}>
                                            <Col xl={{span:10,offset:1}} className={'padding_none_input'}>
                                                <Form onSubmit={sendEmail}>
                                                    <Form.Group>
                                                        <Form.Label for='name_lastname'>Անուն, ազգանուն</Form.Label>
                                                        <Form.Control type='text' id={'name_lastname'} placeholder={'Անի Ադամյան'} name={'name-lastname'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_phone'>Հեռախոսահամար</Form.Label>
                                                        <Form.Control type='number' id={'for_phone'} placeholder={'+374 00 00 00 00'} name={'phone-number'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Կրթություն</Form.Label>
                                                        <Form.Control as="select" id={'for_education'} name={'education'}>
                                                            <option value="Միջնակարգ">Միջնակարգ</option>
                                                            <option value="Բակալավր">Բակալավր</option>
                                                            <option value="Մագիստրոս">Մագիստրոս</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_staff'>Հաստիք</Form.Label>
                                                        <Form.Control type='text' id={'for_staff'} value={'Հաստոցի մեքենավար'} name={'staff'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label for='for_email'>Էլ. հասցե</Form.Label>
                                                        <Form.Control type='email' id={'for_email'} placeholder={'example@mail.ru'} name={'email_address'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label htmlFor={'description'}>Նկարագրություն</Form.Label>
                                                        <Form.Control as="textarea" id={'description'} name={'description'} placeholder="Ողջույն..." rows={3} />
                                                    </Form.Group>
                                                    <div className={'for_sbm_and_cv'}>
                                                        <input type={'submit'} className={'right_btn_cbm'} value={'Ուղարկել'} />
                                                        {/*<input type={'submit'} className={'left_btn_cv'} value={'Ուղարկել'} />*/}
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{ span:4,offset:0}} sm={{span:5,offset:1}} xs={{span:6,offset:0}} className={'mt-5'}>
                            <div className={'div_for_jobs'}>
                                <div className={'for_carer_desc'}>
                                    <FormattedMessage id={'career_desc7'} />
                                </div>
                                <div className={'for_carer_img'}>
                                    <img src={carer7} className={'img-fluid'} alt=""/>
                                </div>

                                <div className={'for_carer_btn'}>
                                    <button type="button"  className={'button_for_apply'} onClick={this.toggleModal6}>
                                        <FormattedMessage id={'button_value'} />
                                    </button>
                                </div>
                            </div>
                            <CSSTransition
                                in={this.state.isOpen6}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen6}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal6} style={{float:'right'}} >X</button>
                                    </div>


                                    <Container className={'row_for_modal_title'}>
                                        <Row >
                                            <Col xl={{span:12,offset:0}} className={'modal_title text-center'}>
                                                Ողջույն, <br/>
                                                կարող եք լրացնել հայտը, կամ կցել րեզյումե
                                            </Col>
                                        </Row>
                                        <Row className={'row_for_form'}>
                                            <Col xl={{span:10,offset:1}} className={'padding_none_input'}>
                                                <Form onSubmit={sendEmail}>
                                                    <Form.Group>
                                                        <Form.Label for='name_lastname'>Անուն, ազգանուն</Form.Label>
                                                        <Form.Control type='text' id={'name_lastname'} placeholder={'Անի Ադամյան'} name={'name-lastname'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_phone'>Հեռախոսահամար</Form.Label>
                                                        <Form.Control type='number' id={'for_phone'} placeholder={'+374 00 00 00 00'} name={'phone-number'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Կրթություն</Form.Label>
                                                        <Form.Control as="select" id={'for_education'} name={'education'}>
                                                            <option value="Միջնակարգ">Միջնակարգ</option>
                                                            <option value="Բակալավր">Բակալավր</option>
                                                            <option value="Մագիստրոս">Մագիստրոս</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_staff'>Հաստիք</Form.Label>
                                                        <Form.Control type='text' id={'for_staff'} value={'Հաշվետար - օպերատոր'} name={'staff'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label for='for_email'>Էլ. հասցե</Form.Label>
                                                        <Form.Control type='email' id={'for_email'} placeholder={'example@mail.ru'} name={'email_address'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label htmlFor={'description'}>Նկարագրություն</Form.Label>
                                                        <Form.Control as="textarea" id={'description'} name={'description'} placeholder="Ողջույն..." rows={3} />
                                                    </Form.Group>
                                                    <div className={'for_sbm_and_cv'}>
                                                        <input type={'submit'} className={'right_btn_cbm'} value={'Ուղարկել'} />
                                                        {/*<input type={'submit'} className={'left_btn_cv'} value={'Ուղարկել'} />*/}
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{ span:4,offset:0}} sm={{span:5,offset:1}} xs={{span:6,offset:0}} className={'mt-5'}>
                            <div className={'div_for_jobs'}>
                                <div className={'for_carer_desc'}>
                                    <FormattedMessage id={'career_desc8'} />
                                </div>
                                <div className={'for_carer_img'}>
                                    <img src={carer8} className={'img-fluid'} alt=""/>
                                </div>

                                <div className={'for_carer_btn'}>
                                    <button type="button"  className={'button_for_apply'} onClick={this.toggleModal7}>
                                        <FormattedMessage id={'button_value'} />
                                    </button>
                                </div>
                            </div>
                            <CSSTransition
                                in={this.state.isOpen7}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen7}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal7} style={{float:'right'}} >X</button>
                                    </div>


                                    <Container className={'row_for_modal_title'}>
                                        <Row >
                                            <Col xl={{span:12,offset:0}} className={'modal_title text-center'}>
                                                Ողջույն, <br/>
                                                կարող եք լրացնել հայտը, կամ կցել րեզյումե
                                            </Col>
                                        </Row>
                                        <Row className={'row_for_form'}>
                                            <Col xl={{span:10,offset:1}} className={'padding_none_input'}>
                                                <Form onSubmit={sendEmail}>
                                                    <Form.Group>
                                                        <Form.Label for='name_lastname'>Անուն, ազգանուն</Form.Label>
                                                        <Form.Control type='text' id={'name_lastname'} placeholder={'Անի Ադամյան'} name={'name-lastname'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_phone'>Հեռախոսահամար</Form.Label>
                                                        <Form.Control type='number' id={'for_phone'} placeholder={'+374 00 00 00 00'} name={'phone-number'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Կրթություն</Form.Label>
                                                        <Form.Control as="select" id={'for_education'} name={'education'}>
                                                            <option value="Միջնակարգ">Միջնակարգ</option>
                                                            <option value="Բակալավր">Բակալավր</option>
                                                            <option value="Մագիստրոս">Մագիստրոս</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_staff'>Հաստիք</Form.Label>
                                                        <Form.Control type='text' id={'for_staff'} value={'Պատվեր հավաքող'} name={'staff'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label for='for_email'>Էլ. հասցե</Form.Label>
                                                        <Form.Control type='email' id={'for_email'} placeholder={'example@mail.ru'} name={'email_address'}  />
                                                    </Form.Group>
                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label htmlFor={'description'}>Նկարագրություն</Form.Label>
                                                        <Form.Control as="textarea" id={'description'} name={'description'} placeholder="Ողջույն..." rows={3} />
                                                    </Form.Group>
                                                    <div className={'for_sbm_and_cv'}>
                                                        <input type={'submit'} className={'right_btn_cbm'} value={'Ուղարկել'} />
                                                        {/*<input type={'submit'} className={'left_btn_cv'} value={'Ուղարկել'} />*/}
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
// {/*<form>*/}
// {/*        <label htmlFor="name_lastName" className={'w-100 mt-2'}>*/}
// {/*            <p className={'label_text'}>Անուն, ազգանուն</p>*/}
// {/*            <input type="text" id={'name_lastName'} placeholder={'Անի Ադամյան'} className={'form-control border-input'}/>*/}
// {/*        </label>*/}
// {/*        <label htmlFor="for_phone" className={'w-100 mt-2'}>*/}
// {/*            <p className={'label_text'}>Հեռախոսահամար</p>*/}
// {/*            <input*/}
// {/*                type="number"*/}
// {/*                id={'for_phone'}*/}
// {/*                placeholder={'+374 00 00 00 00'}*/}
// {/*                className={'form-control border-input'}*/}
// {/*                required*/}
// {/*            />*/}
// {/*        </label>*/}
// {/*        <label htmlFor="for_select" className={'w-100 mt-2'}>*/}
// {/*            <p className={'label_text'}>Կրթություն</p>*/}
// {/*            <select name="" id="for_select" className={'form-control border-input'}>*/}
// {/*                <option value="">Միջնակարգ</option>*/}
// {/*                <option value="">Բակալավր</option>*/}
// {/*                <option value="">Մագիստրոս</option>*/}
// {/*             </select>*/}
// {/*        </label>*/}
// {/*        <label htmlFor="for_desc" className={'w-100 mt-2'}>*/}
// {/*            <p className={'label_text'}>Նկարագրություն</p>*/}
// {/*            <textarea*/}
// {/*                name=""*/}
// {/*                id="for_desc" className={'form-control border-input'}*/}
// {/*                rows={7}*/}
// {/*                placeholder={'Ողջույն...'}*/}
// {/*            >*/}
//
// {/*            </textarea>*/}
// {/*        </label>*/}
// {/*        <div className={'for_buttons'}>*/}
// {/*            <label htmlFor="for_file">*/}
// {/*              <p className={'left_btn_add_file'}>*/}
// {/*                  Կցել CV*/}
// {/*              </p>*/}
// {/*                <input type="file" hidden id={'for_file'} />*/}
// {/*            </label>*/}
// {/*            <label htmlFor="">*/}
// {/*                <input*/}
// {/*                    type={'submit'}*/}
// {/*                    className={'right_btn_add_file'}*/}
// {/*                    value={'Ուղարկել'}*/}
// {/*                />*/}
//
// {/*            </label>*/}
// {/*        </div>*/}
// {/*    </form>*/}

