import React from 'react';
import {Container, Row , Col} from "react-bootstrap";
import ProductNavbar from "../Components/ProductNavbar";
function Product () {
    return (
        <Container>
            <Row>
                <Col xl={3}>
                    <ProductNavbar />
                </Col>
                <Col xl={9}>

                </Col>
            </Row>
        </Container>
    )
}
export default Product