import React ,{useState} from  'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";
import '../../../assets/css/Product/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Modal from "react-modal";
import ReactModal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../../../assets/css/ModalCarousel/style.css";
//import brands img
import SilkLogo from '../../../assets/img/home/logo/SilkLogo.webp';
import PianoLogo from '../../../assets/img/home/logo/piano svg.webp';
import VitaLogo from '../../../assets/img/home/logo/vita svg.webp';

import silk10058 from "../../../assets/img/product/ayl/10058 120 10 axbi park.jpg";
import silk4370 from "../../../assets/img/product/ayl/alcogel 220 ml 4370.jpg";
import silk8059 from "../../../assets/img/product/ayl/alcospray 50ml 8059.jpg";
import silk4363 from "../../../assets/img/product/ayl/alcospray 500ml  4363.jpg";
import silk4989 from "../../../assets/img/product/ayl/alkogel 500l 4989.jpg";
import silk30476 from "../../../assets/img/product/ayl/axbi park 30 60 30476.jpg";
import silk30469 from "../../../assets/img/product/ayl/axbi park 60 20 30469.jpg";
import silk7150 from "../../../assets/img/product/ayl/mijadir silksoft 7+5.jpg";
import silk7643 from "../../../assets/img/product/ayl/mijadir silksoft 10+5.jpg";

import piano4074 from "../../../assets/img/product/ayl/piano/piano axbi toprak 10h 120l 4074.png";
import piano3005 from "../../../assets/img/product/ayl/piano/piano axbi toprak 20h60l 3005.png";
import piano2992 from "../../../assets/img/product/ayl/piano/piano axbi toprak 30h 30l 2992.png";
import pianoAlcoSpray500ml from "../../../assets/img/product/ayl/piano/alcospray 500ml.jpg";
import pianoAlcoGel250ml from "../../../assets/img/product/ayl/piano/alkogel 250ml.jpg";
import pianoAlcoGel500ml from "../../../assets/img/product/ayl/piano/alkogel 500ml.jpg";
import ProductNavbar from '../ProductNavbar'

//Kitchen 3

//Kitchen 3 end
// const props = {};

export default class Other extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showModal: false,
            showModal2: false,

        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseModal2 = this.handleCloseModal2.bind(this);
    }
    handleOpenModal () {
        this.setState({ showModal: true });
    }
    handleOpenModal2 () {
        this.setState({ showModal2: true });
    }
    handleCloseModal () {
        this.setState({ showModal: false });
    }
    handleCloseModal2 () {
        this.setState({ showModal2: false });
    }
    render() {
        return (
            <>
            <Container>
                <Row>
                    <Col xl={3}>
                        <ProductNavbar />
                    </Col>
                    <Col xl={9}>
                    <Tabs>
                    <Container fluid className={'row-for-tab-lists'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList style={{justifyContent:"center"}}>
                                    <Tab>
                                        <img src={SilkLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                    <Tab>
                                        <img src={PianoLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                    {/*<Tab>*/}
                                    {/*    <img src={VitaLogo} className={'img-fluid'} alt=""/>*/}
                                    {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'row-for-tab-lists-mobile'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList>
                                    <Tab>
                                        <FormattedMessage id={'silk_brand'} />
                                    </Tab>
                                    <Tab>
                                        <FormattedMessage id={'piano_brand'} />
                                    </Tab>
                                    {/*<Tab>*/}
                                    {/*    <FormattedMessage id={'vita_brand'} />*/}
                                    {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'p-0'}>
                        <Row>
                            <Col className={'for_border_bottom'}></Col>
                        </Row>
                    </Container>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk10058} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Աղբի պարկ
                                            </p>
                                            <p className={'tab-content-desc-size'}>120 լիտր, 10 հատ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>8130</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk30476} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Աղբի պարկ
                                            </p>
                                            <p className={'tab-content-desc-size'}>60 լիտր, 20 հատ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>0476</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk30469} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Աղբի պարկ
                                            </p>
                                            <p className={'tab-content-desc-size'}>30 լիտր, 30 հատ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>0469</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4370} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Ալկոգել
                                            </p>
                                            <p className={'tab-content-desc-size'}>220 մլ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>4370</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk8059} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Ալկոսփրեյ
                                            </p>
                                            <p className={'tab-content-desc-size'}>50 մլ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>8059</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4363} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Ալկոսփրեյ
                                            </p>
                                            <p className={'tab-content-desc-size'}>500 մլ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>4363</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4989} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Ալկոգել
                                            </p>
                                            <p className={'tab-content-desc-size'}>500 մլ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>4989</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk7150} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Կանացի միջադիր
                                            </p>
                                            <p className={'tab-content-desc-size'}>7 + 5 հատ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>7150</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk7643} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Կանացի միջադիր
                                            </p>
                                            <p className={'tab-content-desc-size'}>10 + 5 հատ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>7150</p>
                                        </div>
                                    </div>
                                </Col>


                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano4074} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Աղբի պարկ
                                            </p>
                                            <p className={'tab-content-desc-size'}>120 լիտր, 10 հատ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>4074</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano3005} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Աղբի պարկ
                                            </p>
                                            <p className={'tab-content-desc-size'}>60 լիտր, 20 հատ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>3005</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano2992} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Աղբի պարկ
                                            </p>
                                            <p className={'tab-content-desc-size'}>30 լիտր, 30 հատ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>2992</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={pianoAlcoSpray500ml} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Ալկոսփրեյ
                                            </p>
                                            <p className={'tab-content-desc-size'}>500 մլ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={pianoAlcoGel250ml} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Ալկոգել
                                            </p>
                                            <p className={'tab-content-desc-size'}>250 մլ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={pianoAlcoGel500ml} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Ալկոգել
                                            </p>
                                            <p className={'tab-content-desc-size'}>500 մլ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}></p>
                                        </div>
                                    </div>
                                </Col>



                            </Row>
                        </Container>
                    </TabPanel>



                </Tabs>              
                    </Col>
                </Row>
            </Container>
          
            </>
        )
    }
}
