import React ,{useState} from  'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";
import '../../../assets/css/Product/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Modal from "react-modal";
import ReactModal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../../../assets/css/ModalCarousel/style.css";
//import brands img
import SilkLogo from '../../../assets/img/home/logo/SilkLogo.webp';
import PianoLogo from '../../../assets/img/home/logo/piano svg.webp';
import VitaLogo from '../../../assets/img/home/logo/vita svg.webp';
//Silk start
import silk0012_1 from "../../../assets/img/product/NapkinsPol/silk/0012.jpg";
import silk0084_1 from "../../../assets/img/product/NapkinsPol/silk/0084.jpg";
import silk0114_1 from "../../../assets/img/product/NapkinsPol/silk/0114.jpg";
import silk0155_1 from "../../../assets/img/product/NapkinsPol/silk/0155.jpg";
import silk0162_1 from "../../../assets/img/product/NapkinsPol/silk/0162.jpg";
import silk0186_1 from "../../../assets/img/product/NapkinsPol/silk/0186.jpg";
import silk0193_1 from "../../../assets/img/product/NapkinsPol/silk/0193-1.jpg";
import silk0209_1 from "../../../assets/img/product/NapkinsPol/silk/0209-1.jpg";
import silk0216_1 from "../../../assets/img/product/NapkinsPol/silk/0216.jpg";
import silk0285_1 from "../../../assets/img/product/NapkinsPol/silk/0285.jpg";
import silk0315_1 from "../../../assets/img/product/NapkinsPol/silk/0315.jpg";
import silk0360_1 from "../../../assets/img/product/NapkinsPol/silk/0360.jpg";
import silk0368_1 from "../../../assets/img/product/NapkinsPol/silk/0368-1.jpg";
import silk0407_1 from "../../../assets/img/product/NapkinsPol/silk/0407.jpg";
import silk0438_1 from "../../../assets/img/product/NapkinsPol/silk/0438.jpg";
import silk0571_1 from "../../../assets/img/product/NapkinsPol/silk/0571 100 3sh.jpg";
import silk0879_1 from "../../../assets/img/product/NapkinsPol/silk/0879-1.jpg";
import silk1075_1 from "../../../assets/img/product/NapkinsPol/silk/1075 33 33 100.jpg";
import silk1285_1 from "../../../assets/img/product/NapkinsPol/silk/1285.jpg";
import silk4400_1 from "../../../assets/img/product/NapkinsPol/silk/4400.jpg";
import silk5152_1 from "../../../assets/img/product/NapkinsPol/silk/5152.jpg";
import silk5445_1 from "../../../assets/img/product/NapkinsPol/silk/5445-1.jpg";
import silk7652_1 from "../../../assets/img/product/NapkinsPol/silk/7652.jpg";
import silk10115_1 from "../../../assets/img/product/NapkinsPol/silk/10115.jpg";
import silk40510_1 from "../../../assets/img/product/NapkinsPol/silk/40510-1.jpg";
//Silk end
//Piano start
  import piano0114 from "../../../assets/img/product/NapkinsPol/piano/0114.jpg";
  import piano0270_1 from "../../../assets/img/product/NapkinsPol/piano/0270.jpg";
  import piano0270_2 from "../../../assets/img/product/NapkinsPol/piano/0270-2.jpg";
  import piano0287_1 from "../../../assets/img/product/NapkinsPol/piano/0287.jpg";
  import piano0287_2 from "../../../assets/img/product/NapkinsPol/piano/0287-2.jpg";
  import piano0492 from "../../../assets/img/product/NapkinsPol/piano/0492.jpg";
  import piano1154_1 from "../../../assets/img/product/NapkinsPol/piano/1154-1.jpg";
  import piano1255_1 from "../../../assets/img/product/NapkinsPol/piano/1255.jpg";
  import piano1255_2 from "../../../assets/img/product/NapkinsPol/piano/1255-2 28x28.jpg";
  import piano1933_1 from "../../../assets/img/product/NapkinsPol/piano/1933-1.jpg";
  import piano6439 from "../../../assets/img/product/NapkinsPol/piano/6439.jpg";
  import piano6842 from "../../../assets/img/product/NapkinsPol/piano/6842.jpg";
// vita  start
import vita0084 from "../../../assets/img/product/NapkinsPol/vita/0084-1.jpg";
import vita0338 from "../../../assets/img/product/NapkinsPol/vita/0338-1.jpg";
import vita0345 from "../../../assets/img/product/NapkinsPol/vita/0345-1 1.jpg";
import vita0345_1 from "../../../assets/img/product/NapkinsPol/vita/0345-1.jpg";
import vita0345_2 from "../../../assets/img/product/NapkinsPol/vita/0345-2.jpg";
import vita1455 from "../../../assets/img/product/NapkinsPol/vita/1455-1.jpg";
import vita5445 from "../../../assets/img/product/NapkinsPol/vita/5445.jpg";


// vita  end
//Piano end
import piano0013_1 from "../../../assets/img/product/NapkinsBox/piano/0013-1.jpg";




// const props = {};

export default class Napkins extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showModal: false,
            showModal2: false,

        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseModal2 = this.handleCloseModal2.bind(this);
    }


    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleOpenModal2 () {
        this.setState({ showModal2: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false });
    }

    handleCloseModal2 () {
        this.setState({ showModal2: false });
    }

    render() {
        return (
            <>
                <Tabs>
                    <Container fluid className={'row-for-tab-lists'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList style={{justifyContent:"center"}}>
                                    <Tab>
                                        <img src={SilkLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                    <Tab>
                                        <img src={PianoLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                     <Tab>
                                        <img src={VitaLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'row-for-tab-lists-mobile'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList>
                                    <Tab>
                                        <FormattedMessage id={'silk_brand'} />
                                    </Tab>
                                    <Tab>
                                        <FormattedMessage id={'piano_brand'} />
                                    </Tab>
                                     <Tab>
                                        <FormattedMessage id={'vita_brand'} />
                                    </Tab>
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'p-0'}>
                        <Row>
                            <Col className={'for_border_bottom'}></Col>
                        </Row>
                    </Container>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0012_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>250 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>90012</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0084_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>220 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>00084</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0114_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>220 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>00114</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0155_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>75 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0155</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0162_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>50 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0162</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0186_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0186</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0193_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>10 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0193</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0209_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>75 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0209</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0216_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>35 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0216</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0285_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0285</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0315_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>160 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0315</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0360_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>50 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0360</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0368_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>220 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0368</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0407_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>50 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0407</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0438_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0438</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0571_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0571</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0879_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0879</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1075_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1075</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1285_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1285</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk5152_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>5152</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk5445_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>220 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>5445</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk7652_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>300 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>7652</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk10115_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>10115</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk40510_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>180 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>40510</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4400_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>4400</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0114} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0114</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0270_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0270</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0270_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0270</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0287_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0287</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0287_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0287</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0492} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0492</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1154_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1154</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1255_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1255</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1255_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1255</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1933_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>10 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1933</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano6439} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>6439</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano6842} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>50 հատ, 1 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>6842</p>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={vita0084} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0084</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={vita0338} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0338</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={vita0345} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0345</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={vita0345_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0345</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={vita0345_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0345</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={vita1455} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1455</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={vita5445} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>5445</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                </Tabs>
            </>
        )
    }
}
