import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import '../assets/css/Admin/style.css'
export default class componentName extends Component {
    render() {
        return (
            <div>
                <ToastContainer />
                <h1>
                    Admin Pnael
                </h1>
            </div>
        )
    }
}
