import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import {Link, NavLink} from "react-router-dom";
import '../../../assets/css/About/style.css';
import {FormattedMessage} from "react-intl";
import {Tab, TabList, TabPanel,Tabs} from "react-tabs";
import BigBrandSilk from '../../../assets/img/home/logo/SilkLogo.webp';
import BigBrandPiano from '../../../assets/img/home/logo/piano svg.webp';
import BigBrandVita from '../../../assets/img/home/logo/vita svg.webp';
import sliderBrandIcon1 from "../../../assets/img/brands/sliderBrandIcon1.webp";
import sliderBrandIcon2 from "../../../assets/img/brands/sliderBrandIcon2.webp";
import sliderBrandIcon3 from "../../../assets/img/brands/sliderBrandIcon3.webp";
import sliderBrandIcon4 from "../../../assets/img/brands/sliderBrandIcon4.webp";
import sliderBrandIcon5 from "../../../assets/img/brands/sliderBrandIcon5.webp";
import sliderBrandIcon6 from "../../../assets/img/brands/sliderBrandIcon6.webp";
import TabSilkImg from '../../../assets/img/brands/TabSilkImg.webp';
import TabPianoImg from '../../../assets/img/brands/TabPianoImg.webp';
import TabVitaImg from '../../../assets/img/brands/TabVitaImg.webp';
import leftSlaq from "../../../assets/img/service/leftSlaq.png";

export default class Brands extends React.Component {
    render () {
        return (
            <>
            <Container fluid className={'first-brand-fluid-section'}>
                <Container className={'section_for_brands'}>
                    <Row>
                        <Col xl={{span:12,offset:0}} lg={{span:12,offset:0}} md={{span:12,offset:0}} sm={{span:12,offset:0}} xs={{span:12,offset:0}} className={'brands_title'}>
                            <FormattedMessage id={'link_brands'} />
                        </Col>
                        <Col xl={{span:8,offset:2}} lg={{span:8,offset:2}} className={'brands-desc'}>
                            Արդեն տասը տարի է, ինչ մեր ապրանքային նշաները  գրավել են իրենց առաջատար և ուրույն տեղը հայկական շուկայում ու դարձել սիրելի և վստահելի սպառողների համար։
                        </Col>
                    </Row>


                </Container>
            </Container>
            <Tabs>
                <Container fluid className={'section_for_br_tab'}>
                    <Container className={'brands-tab-list'}>
                        <Row>
                            <Col xl={{span:8,offset:2}}  className={''}>
                                <TabList>
                                    <Tab>
                                        <img src={BigBrandSilk}  className={'img-fluid mobile-size-none'} alt=""/>
                                    </Tab>
                                    <Tab>
                                        <img src={BigBrandPiano} className={'img-fluid mobile-size-none'} alt=""/>
                                    </Tab>
                                    <Tab>
                                        <img src={BigBrandVita} className={'img-fluid mobile-size-none'} alt=""/>
                                    </Tab>
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container className={'brands-tab-list-mobile'}>
                        <Row>
                            <Col xl={{span:8,offset:2}}  className={''}>
                                <TabList>
                                    <Tab>
                                        Սիլկ Սոֆթ
                                    </Tab>
                                    <Tab>
                                        Պիանո
                                    </Tab>
                                    <Tab>
                                        Վիտա
                                    </Tab>
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                    <TabPanel>
                        <Container className={'brand-tab-cont'}>
                            <Row>
                                <Col xl={{span:7,offset:0}} lg={{span:7,offset:0}} md={{span:7,offset:0}} sm={{span:7,offset:0}}>
                                    <div className={'brand_slider_title_desc'}>
                                        <p className={'brand_slider_title'}>
                                            <img src={BigBrandSilk} className={'img-fluid'} alt=""/>
                                        </p>

                                        <p className={'brand_slider_desc_second'}>
                                            Սիլկ Սոֆթը հանդիսանում է  Հայաստանյան շուկայում թղթե
                                            հիգիենիկ արտադրանքի առաջատար ապրանքանիշը։ Գտնվում է գնային
                                            «միջին պլյուս» սեգմենտում։ Արտադրվում է էկոլոգիապես մաքուր հումքից։ Սիլկ Սոֆթն ունի ավելի քան 300 անուն
                                            ապրանք՝ թղթե անձեռոցիկներ, զուգարանի թուղթ, թղթե սրբիչներ,Z – ծալվածքի սրբիչներ, L – ծալվածքի անձեռոցիկներ, խոնավ անձեռոցիկներ,
                                            բամբակ, բամբակե սկավառակներ, բամբակե փայտիկներ, աղբի տոպրաներ, ախտահանիչ գել և սփրեյ և այլն ։
                                        </p>

                                    </div>
                                </Col>
                                <Col xl={{span:4,offset:0}} lg={{span:5,offset:0}} md={{span:5,offset:0}} sm={{span:5,offset:0}} className={'content-for-img'}>
                                    <img src={TabSilkImg} className={'img-fluid'} alt=""/>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel>
                        <Container className={'brand-tab-cont'}>
                            <Row>
                                <Col xl={{span:7,offset:0}} lg={{span:7,offset:0}} md={{span:7,offset:0}} sm={{span:7,offset:0}}>
                                    <div className={'brand_slider_title_desc'}>
                                        <p className={'brand_slider_title'}>
                                            <img src={BigBrandPiano} className={'img-fluid'} alt=""/>
                                        </p>

                                        <p className={'brand_slider_desc_second'}>
                                            Պիանոն հանդիսանում է արագ զարգացող հայկական ապրանքանիշ, որի հիմքում ընկած է՝ բարձր որակի և մատչելի գնի համադրության սկզբունքը։
                                            Արտադրվում է  էկոլոգիապես մաքուր հուքից։ Պիանո ապրանքանիշն ունի ավելի քան 150 անուն ապրանք՝ թղթե անձեռոցիկներ՝ ստվարաթղթե տոփով և պոլիէթիլենային թաղանթով, զուգարանի թուղթ (1ից - 3 շերտ), թղթե սրբիչներ (2 և 3 շերտ), խոնավ անձեռոցիկներ, բամբակ, բամբակե փայտիկներ, աղբի տոպրաներ։

                                        </p>

                                    </div>
                                </Col>
                                <Col xl={{span:4,offset:0}} lg={{span:5,offset:0}} md={{span:5,offset:0}} sm={{span:5,offset:0}} className={'content-for-img'}>
                                    <img src={TabPianoImg} className={'img-fluid'} alt=""/>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                <TabPanel>
                        <Container className={'brand-tab-cont'}>
                            <Row>
                                <Col xl={{span:7,offset:0}} lg={{span:7,offset:0}} md={{span:7,offset:0}} sm={{span:7,offset:0}}>
                                    <div className={'brand_slider_title_desc'}>
                                        <p className={'brand_slider_title'}>
                                            <img src={BigBrandVita} className={'img-fluid vita-logo'} alt=""/>
                                        </p>

                                        <p className={'brand_slider_desc_second'}>
                                            Վիտան լատիներենից թարգմանաբար նշանակում է կյանք։ Բրենդը հիմնված է շրջակա միջավայրի պահպանման, բնական ռեսուրսների խնայողաբար օգտագործման և շրջակա միջավայրի վրա նվազագույն ազդեցություն ունենալու սկզբունքների վրա։
                                            Վիտա ապրանքանիշը սպառողներին է առաջարկում  բարձրորակ, էկոլոգապես մաքուր արտադրանք՝ մրցունակ գնով։

                                        </p>

                                    </div>
                                </Col>
                                <Col xl={{span:4,offset:0}} lg={{span:5,offset:0}} md={{span:5,offset:0}} sm={{span:5,offset:0}} className={'content-for-img'}>
                                    <img src={TabVitaImg} className={'img-fluid'} alt=""/>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
            </Tabs>



                <Container fluid className={'for_icons'}>
                    <Container>
                        <Row className={'for_slider_icon'}>
                            <Col xl={{span:5,offset:1}} lg={{span:6,offset:0}} md={{span:6,offset:0}} sm={{span:6,offset:0}} xs={{span:10,offset:1}} className={'left-content'}>
                                <p className={'left-title'}>
                                    {/*<FormattedMessage id={'nav_third'} />*/}
                                </p>
                                <p className={'left-desc'}>
                                    Մեր արտադրատեսակները արտադրված են բարձրորակ 100% ցելյուլոզայից և համապատասխանում են
                                    սանիտարահիգիենիկ արտադրանքին ներկայացվող բոլոր պահանջներին։
                                </p>
                            </Col>
                            <Col xl={{span:4,offset:1}} lg={{span:6,offset:0}} md={{span:6,offset:0}} sm={{span:6,offset:0}}  className={'flex-content-for-circle'}>
                                <Row>
                                    <Col xl={2} lg={2} md={2} sm={4} xs={4}>
                                        <div className={'slider_icon_circle_div'}>
                                            <img src={sliderBrandIcon1} className={'img-fluid'} alt=""/>
                                        </div>
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={4} xs={4}>
                                        <div className={'slider_icon_circle_div'}>
                                            <img src={sliderBrandIcon2} className={'img-fluid'} alt=""/>
                                        </div>
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={4} xs={4}>
                                        <div className={'slider_icon_circle_div'}>
                                            <img src={sliderBrandIcon3} className={'img-fluid'} alt=""/>
                                        </div>
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={4} xs={4}>
                                        <div className={'slider_icon_circle_div'}>
                                            <img src={sliderBrandIcon4} className={'img-fluid'} alt=""/>
                                        </div>
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={4} xs={4}>
                                        <div className={'slider_icon_circle_div'}>
                                            <img src={sliderBrandIcon5} className={'img-fluid'} alt=""/>
                                        </div>
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={4} xs={4}>
                                        <div className={'slider_icon_circle_div'}>
                                            <img src={sliderBrandIcon6} className={'img-fluid'} alt=""/>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container>
                    <Row>
                        <Col xl={12} className={'end-prew'}>
                            <Link to='/about'>
                                <span><img src={leftSlaq} alt=""/></span><span>
                                <FormattedMessage id={'return'} />
                            </span>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}