import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import '../../../assets/css/Partners/style.css';
import PartnersImg from "../../../assets/img/partners/partners.webp";
import {FormattedMessage} from "react-intl";
import partner1 from "../../../assets/img/partners/partner1.jpg";
import partner2 from "../../../assets/img/partners/partner2.jpg";
import partner3 from "../../../assets/img/partners/partner3.jpg";
import partner4 from "../../../assets/img/partners/partner4.png";
import partner5 from "../../../assets/img/partners/partner5.jpg";
import partner6 from "../../../assets/img/partners/partner6.jpg";
import partner7 from "../../../assets/img/partners/partner7.jpg";
import partner8 from "../../../assets/img/partners/partner8.jpg";
import partner9 from "../../../assets/img/partners/partner9.jpg";
import partner10 from "../../../assets/img/partners/partner10.jpg";
import partner11 from "../../../assets/img/partners/partner11.jpg";
import partner12 from "../../../assets/img/partners/partner12.jpg";
import partner13 from "../../../assets/img/partners/partner13.jpg";
import partner14 from "../../../assets/img/partners/partner14.png";
import partner15 from "../../../assets/img/partners/partner15.jpg";
import partner16 from "../../../assets/img/partners/partner16.jpg";
import partner17 from "../../../assets/img/partners/partner17.jpg";
import partner18 from "../../../assets/img/partners/partner18.jpg";

export default class Partners extends React.Component {
    render() {
        return (
            <>
                <Container className={'fluid_for_partners'}>
                    <Container>
                        <Row>
                            <Col xl={{span:7,offset:0}} lg={{span:7,offset:0}} md={{span:7,offset:0}} sm={{span:10,offset:1}} className={'left_col_partners_text'}>
                                <div className={'partners_title_description'}>
                                    <p className={'partners_title'}>
                                        <FormattedMessage  id={'nav_forth'}/>
                                    </p>
                                    <p className={'partners_desc_first'}>
                                        Մենք համագործակցում ենք ինչպես մեծածախ, այնպես էլ մանրածախ և ցանցային վաճառակետերի, հյուրանոցային համալիրների, սրճարանների,
                                        ռեստորանների և գրասենյակների հետ։
                                        Բարձր գնահատելով մեզ ընձեռած վստահությունը՝ անում ենք ամենը, որպեսզի լինենք լավագույն և վստահելի մատակարար մեր գործընկերների համար։
                                    </p>
                                </div>
                            </Col>


                            <Col xl={{span:5,offset:0}} lg={{span:5,offset:0}} md={{span:5,offset:0}} sm={{span:10,offset:1}} className={'right_col_partners_img'}>
                                <img src={PartnersImg} className={'img-fluid w-100'} alt=""/>
                            </Col>

                        </Row>
                    </Container>
                </Container>
                <Container className={'for_partners_logoS'}>
                    <Row>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner1} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner2} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col small-icon'}>
                            <img src={partner3} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner4} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner5} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner6} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner7} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner8} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner9} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col small-icon'}>
                            <img src={partner10} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner11} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner12} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner13} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner14} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner15} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner16} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col'}>
                            <img src={partner17} className={'img-fluid'} alt=""/>
                        </Col>
                        <Col md={{span:3,offset:0}}  sm={{span:4,offset:0}}  xs={{span:6,offset:0}} className={'logo_col small-icon'}>
                            <img src={partner18} className={'img-fluid'} alt=""/>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}
