import React , {Component} from 'react';
import {Container,Row,Col,Carousel,} from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import '../../assets/css/Home/style.css';
import Replogo1 from '../../assets/img/representation/Tab1.jpg';
import Replogo2 from '../../assets/img/home/Replogo2.webp';
import Replogo3 from '../../assets/img/home/Replogo3.webp';
import Replogo4 from '../../assets/img/home/Replogo4.webp';
import Replogo5 from '../../assets/img/home/Replogo5.webp';

const config = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay:true,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [{
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
        }
    }]
};
const products = [
    {
        img: Replogo1,
        title: 'Dolore magna',
        text: 'Lorem ipsum dolor sit amet elit.'
    },
    {
        img: Replogo2,
        title: 'Eget est lorem',
        text: 'Lorem Ipsum adipiscing elit ipsum.'
    },
    {
        img: Replogo3,
        title: 'Tempus imperdiet',
        text: 'Orci porta non pulvinar neque laoreet.'
    },
    {
        img:Replogo4,
        title: 'Mattis rhoncus',
        text: 'Bibendum neque egestas congue quisque.'
    },
    {
        img:Replogo5,
        title: 'Odio ut enim',
        text: 'Mattis rhoncus urna neque viverra justo.'
    }
]
export default class SliderRep extends Component {
    render(){
        return (
            <Slider {...config}>
                {products.map((x, i) => {
                    return <div key="{i}" className="img-card">
                        <img className="img img-fluid" src={x.img} alt={x.title} />
                    </div>
                })}
            </Slider>
        )
    }
}
