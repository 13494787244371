import React from 'react';
import {Col, Row, Container, Nav, Accordion, Card, Button} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";
import {BrowserRouter, Link, Route} from "react-router-dom";
import ProductOther from "./ProductComponents/Other";

const ProductNavbar = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col xl={12}>
                        <Container fluid>
                            <Row>
                                <Col>
                                    {/*<Nav className={'flex-column left-side-nav'}>*/}
                                    {/*                                                                */}
                                    {/*</Nav>*/}
                                    <Accordion >
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                   <Link>
                                       <FormattedMessage id={'product_menu_first'} />
                                   </Link>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>Hello! I'm the body</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                    Click me!
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>Hello! I'm another body</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        <BrowserRouter>
            <Route exact path="/" component={ProductOther} />
        </BrowserRouter>
        </div>
    );
};


export default ProductNavbar;