import React ,{useState} from  'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";
import '../../../assets/css/Product/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Modal from "react-modal";
import ReactModal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../../../assets/css/ModalCarousel/style.css";
//import brands img
import SilkLogo from '../../../assets/img/home/logo/SilkLogo.webp';
import PianoLogo from '../../../assets/img/home/logo/piano svg.webp';
import VitaLogo from '../../../assets/img/home/logo/vita svg.webp';
//import SilkBox img
import silk0018_1 from '../../../assets/img/product/NapkinsBox/0018-1.jpg';
import silk0018_2 from '../../../assets/img/product/NapkinsBox/0018-2.jpg';
import silk0020 from '../../../assets/img/product/NapkinsBox/0020-1.jpg';
import silk0025_1 from "../../../assets/img/product/NapkinsBox/0025-1.jpg";
import silk0025_2 from "../../../assets/img/product/NapkinsBox/0025-2.jpg";
import silk0025_3 from "../../../assets/img/product/NapkinsBox/0025-3.jpg";
import silk0025_4 from "../../../assets/img/product/NapkinsBox/0025-4.jpg";
import silk0025_5 from "../../../assets/img/product/NapkinsBox/0025-5.jpg";
import silk0025_6 from "../../../assets/img/product/NapkinsBox/0025-6.jpg";
import silk0025_7 from "../../../assets/img/product/NapkinsBox/0025-7.jpg";
import silk0044_1 from "../../../assets/img/product/NapkinsBox/0044-1.jpg";
import silk0049 from "../../../assets/img/product/NapkinsBox/0049.jpg";
import silk0056_1 from "../../../assets/img/product/NapkinsBox/0056-1.jpg";
import silk0070 from "../../../assets/img/product/NapkinsBox/0070.jpg";
import silk0075_1 from "../../../assets/img/product/NapkinsBox/0075-1.jpg";
import silk0087 from "../../../assets/img/product/NapkinsBox/0087.jpg";
import silk0094_1 from "../../../assets/img/product/NapkinsBox/0094-1.jpg";
import silk0099_1 from "../../../assets/img/product/NapkinsBox/0099-1.jpg";
import silk0100_1 from "../../../assets/img/product/NapkinsBox/0100-1.jpg";
import silk0117_1 from "../../../assets/img/product/NapkinsBox/0117-1.jpg";
import silk0124 from "../../../assets/img/product/NapkinsBox/0124.jpg";
import silk0131_1 from "../../../assets/img/product/NapkinsBox/0131-1.jpg";
import silk0148 from "../../../assets/img/product/NapkinsBox/0148-1.jpg";
import silk0195_1 from "../../../assets/img/product/NapkinsBox/0195-1.jpg";
import silk0232_1 from "../../../assets/img/product/NapkinsBox/0232-1.jpg";
import silk0247_1 from "../../../assets/img/product/NapkinsBox/0247-1.jpg";
import silk0292_1 from "../../../assets/img/product/NapkinsBox/0292-1.jpg";
import silk0339_1 from "../../../assets/img/product/NapkinsBox/0339-1.jpg";
import silk0363_1 from "../../../assets/img/product/NapkinsBox/0363-1.jpg";
import silk1321_1 from "../../../assets/img/product/NapkinsBox/1321-1.jpg";
import silk1583_1 from "../../../assets/img/product/NapkinsBox/1583-1.jpg";
import silk1607_1 from "../../../assets/img/product/NapkinsBox/1607.jpg";
import silk1616 from "../../../assets/img/product/NapkinsBox/1616.jpg";
import silk4800_1 from "../../../assets/img/product/NapkinsBox/4800-1.jpg";
import silk14572 from "../../../assets/img/product/NapkinsBox/14572.jpg";
import silk_syuniq from "../../../assets/img/product/NapkinsBox/syuniq.jpg";
//3shert
import silk0022_1 from "../../../assets/img/product/NapkinsBox/3shert/0022-1.jpg";
import silk0072_1 from "../../../assets/img/product/NapkinsBox/3shert/0072-1.jpg";
import silk0112_1 from "../../../assets/img/product/NapkinsBox/3shert/0112-1.jpg";
import silk0179_1 from "../../../assets/img/product/NapkinsBox/3shert/0179-1.jpg";
import silk0278 from "../../../assets/img/product/NapkinsBox/3shert/0278.jpg";
import silk0414_1 from "../../../assets/img/product/NapkinsBox/3shert/0414-1.jpg";
import silk01200_1 from "../../../assets/img/product/NapkinsBox/3shert/1200-1.jpg";
import silk1443_1 from "../../../assets/img/product/NapkinsBox/3shert/1443-1.jpg";
import silk1453_1 from "../../../assets/img/product/NapkinsBox/3shert/1453-1.jpg";
import silk1453_1_2 from "../../../assets/img/product/NapkinsBox/3shert/1453-2 1.jpg";
import silk1614_1 from "../../../assets/img/product/NapkinsBox/3shert/1614-1.jpg";
import silk2521_1 from "../../../assets/img/product/NapkinsBox/3shert/2521-1.jpg";
import silk4387_1 from "../../../assets/img/product/NapkinsBox/3shert/4387-1.jpg";
import silk5792_1 from "../../../assets/img/product/NapkinsBox/3shert/5792-1.jpg";
import silk7301 from "../../../assets/img/product/NapkinsBox/3shert/7301.jpg";
import silk90044_1 from "../../../assets/img/product/NapkinsBox/3shert/90044-1.jpg";
// Silk end
//Piano start

//Piano start end
import piano0013_1 from "../../../assets/img/product/NapkinsBox/piano/0013-1.jpg";
import piano0014_1 from "../../../assets/img/product/NapkinsBox/piano/0014.jpg";
import piano0020_1 from "../../../assets/img/product/NapkinsBox/piano/0020-1.jpg";
import piano0037_1 from "../../../assets/img/product/NapkinsBox/piano/0037.jpg";
import piano0094_1 from "../../../assets/img/product/NapkinsBox/piano/0094.jpg";
import piano0150_1 from "../../../assets/img/product/NapkinsBox/piano/0150-1.jpg";
import piano0151_1 from "../../../assets/img/product/NapkinsBox/piano/0151.jpg";
import piano0158_1 from "../../../assets/img/product/NapkinsBox/piano/0158.jpg";
import piano0175_1 from "../../../assets/img/product/NapkinsBox/piano/0175.jpg";
import piano0225_1 from "../../../assets/img/product/NapkinsBox/piano/0225-1.jpg";
import piano0480_1 from "../../../assets/img/product/NapkinsBox/piano/0480.jpg";
import piano0537_1 from "../../../assets/img/product/NapkinsBox/piano/0537.jpg";
import piano1004_1 from "../../../assets/img/product/NapkinsBox/piano/1004-1.jpg";
import piano1097_1 from "../../../assets/img/product/NapkinsBox/piano/1097.jpg";
import piano1103_1 from "../../../assets/img/product/NapkinsBox/piano/1103-1.jpg";
import piano1854_1 from "../../../assets/img/product/NapkinsBox/piano/1854 3d-1.jpg";
import piano2124_1 from "../../../assets/img/product/NapkinsBox/piano/2124.jpg";
import piano2155_1 from "../../../assets/img/product/NapkinsBox/piano/2155-1.jpg";
import piano2551_1 from "../../../assets/img/product/NapkinsBox/piano/2551-1.jpg";
import piano3176_1 from "../../../assets/img/product/NapkinsBox/piano/3176.jpg";
import piano3190_1 from "../../../assets/img/product/NapkinsBox/piano/3190-1.jpg";
import piano4512_1 from "../../../assets/img/product/NapkinsBox/piano/4512.jpg";
import piano6500_1 from "../../../assets/img/product/NapkinsBox/piano/6500-1.jpg";
import piano8475_1 from "../../../assets/img/product/NapkinsBox/piano/8475.jpg";
import piano9838_1 from "../../../assets/img/product/NapkinsBox/piano/9838-1.jpg";
import piano70017_1 from "../../../assets/img/product/NapkinsBox/piano/70017.jpg";


import Sherter from '../../../assets/img/product/Vectors/Sherter.png';
import Sherter1 from '../../../assets/img/product/Vectors/2126448811760.png';


// const props = {};

export default class Napkins extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showModal: false,
            showModal2: false,

        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseModal2 = this.handleCloseModal2.bind(this);
    }


    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleOpenModal2 () {
        this.setState({ showModal2: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false });
    }

    handleCloseModal2 () {
        this.setState({ showModal2: false });
    }

    render() {
        return (
            <>
                <Tabs>
                    <Container fluid className={'row-for-tab-lists'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList style={{justifyContent:"center"}}>
                                    <Tab>
                                        <img src={SilkLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                    <Tab>
                                        <img src={PianoLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                   {/* <Tab>*/}
                                   {/*    <img src={VitaLogo} className={'img-fluid'} alt=""/>*/}
                                   {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'row-for-tab-lists-mobile'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList>
                                    <Tab>
                                        <FormattedMessage id={'silk_brand'} />
                                    </Tab>
                                    <Tab>
                                        <FormattedMessage id={'piano_brand'} />
                                    </Tab>
                                   {/* <Tab>*/}
                                   {/*    <FormattedMessage id={'vita_brand'} />*/}
                                   {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'p-0'}>
                        <Row>
                            <Col className={'for_border_bottom'}></Col>
                        </Row>
                    </Container>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0018_1} className={'img-fluid'}  title={'Click'} alt=""/>
                                            {/*<img src={silk0018_1} className={'img-fluid'} onClick={this.handleOpenModal} title={'Click'} alt=""/>*/}
                                            {/*<ReactModal*/}
                                            {/*    isOpen={this.state.showModal}*/}
                                            {/*    contentLabel="Modal #1 Global Style Override Example"*/}
                                            {/*    onRequestClose={this.handleCloseModal}*/}
                                            {/*>*/}
                                            {/*    <Carousel autoPlay>*/}
                                            {/*        <div>*/}
                                            {/*            <img src={silk0018_1} className={'img-fluid'} alt=""/>*/}
                                            {/*        </div>*/}
                                            {/*        <div>*/}
                                            {/*            <img src={silk0018_2} className={'img-fluid'} alt=""/>*/}
                                            {/*        </div>*/}

                                            {/*    </Carousel>*/}
                                            {/*    <button onClick={this.handleCloseModal}>Close Modal</button>*/}
                                            {/*</ReactModal>*/}
                                        </div>

                                        {/*<div className={'for_tab_content_text'}>*/}
                                        {/*        <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>*/}
                                        {/*        <p className={'tab-content-desc-size'}>60 հատ, 2 շերտ</p>*/}
                                        {/*        <p className={'tab-content-desc-articule'}>0018</p>*/}
                                        {/*</div>*/}
                                        <div className={'for_tab_content_text'}>
                                                    <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>

                                            <div className={'icon-description'}>
                                                <div className={'icon-description-small'}>
                                                    <div className={'count'}>150</div>
                                                    <div>
                                                        <img src={Sherter} style={{ width: "20px" , height:"20px"}} alt=""/>
                                                    </div>
                                                </div>
                                                <div className={'icon-description-small'}>
                                                <div className={'count'}>3</div>
                                                <div>
                                                    <img src={Sherter1} style={{ width: "20px" , height:"20px"}} alt=""/>
                                                </div>
                                            </div>
                                                <div className={'icon-description-small text'} style={{borderRight:"none"}}>
                                                    0084
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0025_1} className={'img-fluid'}  title={'Click'} alt=""/>
                                            {/*<img src={silk0025_1} className={'img-fluid'} onClick={this.handleOpenModal2}   title={'Click'} alt=""/>*/}
                                            {/*<ReactModal*/}
                                            {/*    isOpen={this.state.showModal2}*/}
                                            {/*    contentLabel="Modal #2 Global Style Override Example"*/}
                                            {/*    onRequestClose={this.handleCloseModal2}*/}
                                            {/*>*/}
                                            {/*    <Carousel autoPlay>*/}
                                            {/*        <div>*/}
                                            {/*            <img src={silk0025_1} className={'img-fluid'} alt=""/>*/}
                                            {/*        </div>*/}
                                            {/*        <div>*/}
                                            {/*            <img src={silk0025_2} className={'img-fluid'} alt=""/>*/}
                                            {/*        </div>*/}
                                            {/*        <div>*/}
                                            {/*            <img src={silk0025_3} className={'img-fluid'} alt=""/>*/}
                                            {/*        </div>*/}
                                            {/*        <div>*/}
                                            {/*            <img src={silk0025_4} className={'img-fluid'} alt=""/>*/}
                                            {/*        </div>*/}
                                            {/*        <div>*/}
                                            {/*            <img src={silk0025_5} className={'img-fluid'} alt=""/>*/}
                                            {/*        </div>*/}
                                            {/*        <div>*/}
                                            {/*            <img src={silk0025_6} className={'img-fluid'} alt=""/>*/}
                                            {/*        </div>*/}
                                            {/*    </Carousel>*/}
                                            {/*</ReactModal>*/}

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0025</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0044_1} className={'img-fluid'}  title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0044</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0049} className={'img-fluid'}  title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0049</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0056_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>85 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0056</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0070} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0070</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0075_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>130 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0075</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0087} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0087</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0094_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0094</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0099_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0099</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0100_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>180 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0100</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0117_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0117</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0124} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>90 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0124</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0131_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0131</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0148} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0148</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0195_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0195</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0247_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>80 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0247</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0292_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0292</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0339_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>80 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0339</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0363_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>160 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0363</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1321_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>180 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1321</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1583_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>80 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1583</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1607_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1607</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1616} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1616</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4800_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>4800</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk14572} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>14572</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk_syuniq} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>130 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>9412</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0022_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0022</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0072_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0072</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0112_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0112</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0179_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0179</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0278} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>120 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0278</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0414_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>80 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0414</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk01200_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1200</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1443_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1443</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1453_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>80 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1453</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1453_1_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>80 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1453</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk1614_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>70 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1614</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk2521_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>2521</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4387_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>4387</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk5792_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>80 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>5792</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk7301} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>70 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>7301</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk90044_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>90044</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0013_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0013</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0014_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>170014</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0020_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0020</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0037_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0020</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0094_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0094</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0150_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>60 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0150</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0151_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>170151</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0158_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>120 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0158</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0175_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0175</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0225_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>80 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0225</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0480_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0480</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano0537_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>120 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>0537</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1004_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>135 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1004</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1097_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1097</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1103_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>180 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1103</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1854_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>80 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>1854</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano2124_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>100 հատ, 3 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>2124</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano2155_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>2155</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano3190_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>3190</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano4512_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>160 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>4512</p>
                                        </div>
                                    </div>
                                </Col>


                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano6500_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>216500</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano8475_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>150 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>8475</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano9838_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>180 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>9838</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano70017_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>

                                        </div>

                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>Թղթե անձեռոցիկ</p>
                                            <p className={'tab-content-desc-size'}>200 հատ, 2 շերտ</p>
                                            <p className={'tab-content-desc-articule'}>70017</p>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    </TabPanel>
                </Tabs>
            </>
        )
    }
}
