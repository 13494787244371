import React ,{Component,useState} from 'react';
import {
    Container,
    Row,
    Col,
    NavItem
} from "react-bootstrap";
import {Collapse, Navbar, NavbarToggler, Nav} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faBars ,faSearch } from "@fortawesome/free-solid-svg-icons";

export default class NavbarPage extends Component {
    constructor(props){
        super(props);
        this.state={
            sideBar: false
        }

        this.handleSidebar = this.handleSidebar.bind(this);
        this.toggle = this.toggle.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            isOpen: false,
        };
        this.state = {
        }
    }

    handleSidebar(){
        this.setState({
            sideBar : !this.state.sideBar
        });
    }
    componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    closeNavbar() {
        this.setState({
            isOpen: false
        });
    }
    handleClickOutside(event) {
        const t = event.target;
        if (this.state.isOpen && !t.classList.contains('navbar-toggler')) {
            this.closeNavbar();
        }
    }
    render(){

        return (
            <>
                <Container fluid id={'section_for_navbar'}>
                    <Container>
                        <Row>
                            <Col xl={12} className={'p-0'}>
                                <div className="topbar" id={'fix_navbar_left'}>
                                    <section className="container p-0">
                                        <Navbar color="" className={'navbar'} expand="xl">
                                            <NavbarToggler onClick={this.toggle}>
                                                <FontAwesomeIcon icon={this.state.isOpen ? faBars : faBars}/>
                                            </NavbarToggler>
                                            <Collapse isOpen={this.state.isOpen} navbar id={'basic-navbar-navs'}>
                                                <Nav className={'navbar_nav'} navbar>
                                                    <NavItem>
                                                        <Link to="/" onClick={this.closeNavbar} className={'nav_item home-page'}>
                                                        <FormattedMessage id={'nav_first'} />
                                                        </Link>
                                                    </NavItem>
                                                    <NavItem>
                                                        <Link to="/about" onClick={this.closeNavbar} className={'nav_item pl-0'}>
                                                            <FormattedMessage id={'nav_second'} />
                                                        </Link>
                                                    </NavItem>

                                                    <NavItem>
                                                        <Link to="/products" onClick={this.closeNavbar} className={'nav_item pl-0'}>
                                                            <FormattedMessage id={'nav_third'} />
                                                        </Link>
                                                    </NavItem>

                                                    <NavItem>
                                                        <Link to="/partners" onClick={this.closeNavbar} className={'nav_item pl-0'}>
                                                            <FormattedMessage id={'nav_forth'} />
                                                        </Link>
                                                    </NavItem>

                                                    <NavItem>
                                                        <Link to="/representation" onClick={this.closeNavbar} className={'nav_item pl-0'}>
                                                            <FormattedMessage id={'nav_fifth'} />
                                                        </Link>
                                                    </NavItem>

                                                    <NavItem>
                                                        <Link to="/career" onClick={this.closeNavbar} className={'nav_item pl-0'}>
                                                            <FormattedMessage id={'nav_sixth'} />
                                                        </Link>
                                                    </NavItem>

                                                    <NavItem>
                                                        <Link to="/news" onClick={this.closeNavbar} className={'nav_item pl-0 prev-link'}>
                                                            <FormattedMessage id={'nav_seventh'} />
                                                        </Link>
                                                    </NavItem>

                                                </Nav>
                                            </Collapse>
                                        </Navbar>
                                    </section>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}