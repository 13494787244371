import React, { Component } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../../../assets/css/Representation/style.css";
import { FormattedMessage } from "react-intl";
import RepBig from '../../../assets/img/representation/RepBig.jpg';
import leftBtn from "../../../assets/img/representation/arrow-left-circle.svg";
import rightBtn from "../../../assets/img/representation/arrow-right-circle.svg";
import Tab1 from '../../../assets/img/representation/Tab1.jpg';
import Tab2 from '../../../assets/img/representation/Tab2.webp';
import Tab3 from '../../../assets/img/representation/Tab3.webp';
import Tab4 from '../../../assets/img/representation/Tab4.webp';
import Tab5 from '../../../assets/img/representation/Tab5.webp';
import OlaSmallIcon from "../../../assets/img/representation/olaSmallLogo.webp";
import OlaBigImg from "../../../assets/img/representation/olaBigImg.webp";
import OlaSilkSmall from '../../../assets/img/representation/olaSilkSmall.webp';
import OlaSilkBig from '../../../assets/img/representation/olaSilkBig.webp';
import TerezaSmall from '../../../assets/img/representation/terezaSmall.webp';
import TerezaBig from '../../../assets/img/representation/terezaBig.webp';
import AcualeSmall from "../../../assets/img/representation/aquaelle.webp";
import AcualeBig from "../../../assets/img/representation/Aquaelle_nkar_1.webp";
import NootaSmall from "../../../assets/img/representation/svejaya-nota.webp";
import NootaBig from "../../../assets/img/representation/svejaya_nota_nkar_2.webp";
import SoffiteSmall from "../../../assets/img/representation/Soffione_PRINT.webp";
import SoffiteBig from "../../../assets/img/representation/soffione_nkar_3.webp";
import tetraSmall from "../../../assets/img/representation/GRATIAS_1.webp";
import tetraBig from "../../../assets/img/representation/GRATIAS_nkar_1.webp";
import MelochSmall from "../../../assets/img/representation/melochi.webp";
import MelochBig from "../../../assets/img/representation/melochi_1_.webp";
import DomiSmall from "../../../assets/img/representation/domi.webp";
import DomiBig from "../../../assets/img/representation/domi_1.webp";
import STR8Small from "../../../assets/img/representation/str8.webp";
import STR8Big from "../../../assets/img/representation/66977948219021_2268315941606176496_n.webp";
import BuSmall from "../../../assets/img/representation/BU_LOGO_HORIZONTAL_BLACK_1.webp";
import BuBig from "../../../assets/img/representation/BU_1.webp";
export default class Representation extends React.Component {
    state = {
        isOpen: false,
        isOpen1: false,
        isOpen2: false,
        isOpen3: false,
        isOpen4: false,
        isOpen5: false,
        isOpen6: false,
        isOpen7: false,
    };
    constructor() {
        super()
        this.state = {
            show: false,
        }
        this.navRef = React.createRef();
    }
    handleModal() {
        this.setState({ show: !this.state.show });
    }
    handleNav = (direction) => {
        if (direction === 'left') {
            // eslint-disable-next-line no-unused-expressions
            this.navRef ? (this.navRef.current.scrollLeft -= 283) : null;
        } else {
            // eslint-disable-next-line no-unused-expressions
            this.navRef ? (this.navRef.current.scrollLeft += 283) : null;
        }
    }
    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };
    toggleModal1 = () => {
        this.setState(prevState => ({
            isOpen1: !prevState.isOpen1,
        }));
    };
    toggleModal2 = () => {
        this.setState(prevState => ({
            isOpen2: !prevState.isOpen2,
        }));
    };
    toggleModal3 = () => {
        this.setState(prevState => ({
            isOpen3: !prevState.isOpen3,
        }));
    };
    toggleModal4 = () => {
        this.setState(prevState => ({
            isOpen4: !prevState.isOpen4,
        }));
    };
    render() {
        const modalStyles = {
            overlay: {
                backgroundColor: 'rgba(0,0,0,.6)',
            },
        };
        return (
            <>
                <Container className={'representation-header-content'}>
                    <Row>
                        <Col xl={{ span: 7, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                            <div className={'representation-text'}>
                                <p className={'representation-title'}>
                                    <FormattedMessage id={'nav_fifth'} />
                                </p>
                                <p className={'representation-desc'}>
                                    «Սոֆթ Թրեյդ» ՍՊԸ-ն հանդիսանում է մի շարք համաշխարային ճանաչում ունեցող ընկերությունների միակ
                                    պաշտոնական ներկայացուցիչը  Հայաստանի Հանրապետությունում ։

                                </p><p className={'representation-desc'}>
                                    Պրոֆեսիոնալ վաճառքի թիմը, զարգացած պահեստային և լոգիստիկ ծառայութունը հնարավորություն է ընձեռում «Սոֆտ Թրեյդ» ՍՊԸ-ին բարձր որակով իրականցնել ստանձնած դիստրիբյուտորական պարտականությունները Հայաստանի Հանրապետության ամբողջ տարածով։

                                </p>
                            </div>
                        </Col>
                        <Col xl={{ span: 5, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }} className={'right-content-img'}>
                            <img src={RepBig} className={'img-fluid w-100'} alt="" />
                        </Col>
                    </Row>
                </Container>
                <Tabs>
                    <div className={'for_computer_size'}>
                        <Container className={'my-5'} fluid>
                            <Row>
                                <Col xl={12} className={'p-0'}>
                                    <Container className={'p-0'} id={'for-select-selected'}>
                                        <button onClick={() => this.handleNav('left')} className={'left-button'}>
                                            <img src={leftBtn} alt="" />
                                        </button>
                                        <TabList>
                                            <div style={{ overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap', transition: 'all .3s linear' }} ref={this.navRef} className={'scrl-content'}>

                                                <Tab>
                                                    <div className={'flex_center_align'}>
                                                        <img src={Tab1} style={{ transform: 'scale(0.8)' }} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className={'flex_center_align'}>
                                                        <img src={Tab2} className={'img-fluid'} alt="" style={{ transform: "scale(3.5)" }} />
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className={'flex_center_align'}>
                                                        <img src={Tab3} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className={'flex_center_align'}>
                                                        <img src={Tab4} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className={'flex_center_align'}>
                                                        <img src={Tab5} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Tab>
                                            </div>
                                        </TabList>
                                        <button onClick={() => this.handleNav('right')} className={'right-button'}>
                                            <img src={rightBtn} alt="" />

                                        </button>
                                    </Container>
                                    <TabPanel>
                                        <Container className={'full-width-desc'} fluid>
                                            <Container>
                                                <Row>
                                                    <Col xl={{ span: 8, offset: 2 }} className={'text-center'}>
                                                        <div className={'title-description'}>
                                                            <p className={'title'}>
                                                                HYGIENE
                                                            </p>
                                                            <p className={'description'}>
                                                                Հայջենիկը հանդիսանում է բազմամյա փորձով, Ռուսաստանի Դաշնության թիվ մեկ՝ կանացի բարձրորակ
                                                                հիգիենայի պարագաներ արտադրողը։ Ընկերության արտադրանքը համապատասխանում է միջազգային որակի բարձր
                                                                ստանդարտներին և արտահանվում է ավելի քան 12 երկիր։  Ընկերություննը առաջարկում է OLA, Ola SilkSense,
                                                                TerezaMed ապրանքային նշանների արտադրանքը։
                                                            </p>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Container>
                                        <Container className={'for_tab_first'}>
                                            <Row>
                                                <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                    <div className={'for_tab_first_title_dsc'}>
                                                        <img src={OlaSmallIcon} alt="" className={'img-fluid title_icon'} />
                                                        <ul className={'tab_ul_desc'}>
                                                            <li>Կանացի միջադիրներ ամենօրյա</li>
                                                            <li>Կանացի միջադիրներ հատուկ օրերի համար</li>
                                                        </ul>

                                                    </div>
                                                </Col>
                                                <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                    <div className={'for_tab1_image'}>
                                                        <img src={OlaBigImg} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className={'for_tab_first_fluid'} fluid>
                                            <Container>
                                                <Row>
                                                    <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                        <div className={'for_tab1_image'}>
                                                            <img src={OlaSilkBig} className={'img-fluid'} alt="" />
                                                        </div>
                                                    </Col>
                                                    <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                        <div className={'for_tab_first_title_dsc'}>
                                                            <img src={OlaSilkSmall} alt="" className={'img-fluid title_icon'} />
                                                            <ul className={'tab_ul_desc'}>
                                                                <li>Բամբակե սկավառակներ</li>
                                                                <li>Բամբակե փաստարկներ</li>
                                                                <li>Խոնավ անձեռցիկներ</li>
                                                            </ul>


                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Container>
                                        </Container>
                                        <Container className={'for_tab_first'}>
                                            <Row>
                                                <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                    <div className={'for_tab_first_title_dsc'}>
                                                        <img src={TerezaSmall} alt="" className={'img-fluid title_icon'} />
                                                        <ul className={'tab_ul_desc'}>
                                                            <li>Տակդիրներ մեծահասակների համար</li>
                                                            <li>Սավաններ մեծահասկաների համար</li>
                                                            <li>Ուրոլոգիական միջադիրներ</li>
                                                        </ul>

                                                    </div>
                                                </Col>
                                                <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                    <div className={'for_tab1_image'}>
                                                        <img src={TerezaBig} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </TabPanel>
                                    <TabPanel>
                                        <Container className={'full-width-desc'} fluid>
                                            <Container>
                                                <Row>
                                                    <Col xl={{ span: 8, offset: 2 }} className={'text-center'}>
                                                        <div className={'title-description'}>
                                                            <p className={'title'}>
                                                                Զեթթեք
                                                            </p>
                                                            <p className={'description'}>
                                                                Զեթթեքը Ռուսաստանի Դաշնությունում ամենաառաջին խոնավ անձեռոցիկների և հիգիենայի պարագաների արտադրողն է։
                                                                Արդեն 25 տարուց ավել ընկերությունը առաջարկում է բարձրորակ  թաց անձեռոցիկներ, խոնավ զուգարանի թուղթ,
                                                                օճառ և այլ հիգիենայի պարագաներ։
                                                                Ընկերությունը առաջարկում է Aquaelle,  Свежая Нота ապրանքային նշանների արտադրանքը։

                                                            </p>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Container>
                                        <Container className={'for_tab_first'}>
                                            <Row>
                                                <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                    <div className={'for_tab_first_title_dsc'}>
                                                        <img src={AcualeSmall} alt="" className={'img-fluid title_icon'} />
                                                        <ul className={'tab_ul_desc'}>
                                                            <li>Խոնավ անձեռոցիկներ</li>
                                                            <li>Զուքարանի Խոնավ թղթեր</li>
                                                            <li>Ձեռքի օճառ</li>
                                                            <li>Դեմքի կոսմետիկ դիմակ</li>
                                                        </ul>


                                                    </div>
                                                </Col>
                                                <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                    <div className={'for_tab1_image'}>
                                                        <img src={AcualeBig} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className={'for_tab_first_fluid'} fluid>
                                            <Container>
                                                <Row>
                                                    <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                        <div className={'for_tab1_image'}>
                                                            <img src={NootaBig} className={'img-fluid'} alt="" />
                                                        </div>
                                                    </Col>
                                                    <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                        <div className={'for_tab_first_title_dsc'}>
                                                            <img src={NootaSmall} alt="" className={'img-fluid title_icon'} style={{ width: "157px", height: "135px" }} />
                                                            <ul className={'tab_ul_desc'}>
                                                                <li>Խոնավ անձեռոցիկներ</li>
                                                                <li>Զուքարանի խոնավ թղթեր</li>
                                                            </ul>

                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Container>
                                        </Container>

                                    </TabPanel>
                                    <TabPanel>
                                        <Container className={'full-width-desc'} fluid>
                                            <Container>
                                                <Row>
                                                    <Col xl={{ span: 8, offset: 2 }} className={'text-center'}>
                                                        <div className={'title-description'}>
                                                            <p className={'title'}>
                                                                Արխբում Թիշյու Գրուպը

                                                            </p>
                                                            <p className={'description'}>
                                                                «Արխբում Թիշյու Գրուպ» ՍՊԸ-ն իր ոլորտում միակ ձեռնարկությունն է Ռուսաստանի Դաշնությունում,
                                                                որը հանդիսանում է հորիզոնական ինտեգրված հոլդինգի մաս, որի շնորհիվ ընկերության արտադրանքը  շուկայում ունի
                                                                մրցակցային առավելություն։
                                                                Ընկերությունը  հագեցած է արդիական արտադրական համալիրով, որն ապահովվում է ապրանքի բարձր որակ և մրցունակ գին։
                                                                Ընկերությունը ներկայացնում է Soffione ապրանքային նշանը։

                                                            </p>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Container>
                                        <Container className={'for_tab_first'}>
                                            <Row>
                                                <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                    <div className={'for_tab_first_title_dsc'}>
                                                        <img src={SoffiteSmall} alt="" className={'img-fluid title_icon'} />
                                                        <ul className={'tab_ul_desc'}>
                                                            <li>Զուգարանի թուղթ</li>
                                                            <li>Թղթե սրբիչ</li>
                                                            <li>Թղթե անձեռոցիկներ</li>
                                                        </ul>

                                                    </div>
                                                </Col>
                                                <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                    <div className={'for_tab1_image'}>
                                                        <img src={SoffiteBig} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>


                                    </TabPanel>
                                    <TabPanel>
                                        <Container className={'full-width-desc'} fluid>
                                            <Container>
                                                <Row>
                                                    <Col xl={{ span: 8, offset: 2 }} className={'text-center'}>
                                                        <div className={'title-description'}>
                                                            <p className={'title'}>
                                                                ՏԵՏՐԱ

                                                            </p>
                                                            <p className={'description'}>
                                                                ՏԵՏՐԱ ընկերությունը, Ռուսաստանի թղթե անձեռոցիների արտադրող ամենաժամանակակից
                                                                ընկերությությունն է։ OMET S.L.R. և  OMMAPACK իտալականն հաստոցները, հնարավորություն են տալիս
                                                                անեռոցիկների վրա ունենալ ֆոտո որակի տպագրություն և ամսական մինչև 3 մլն․ անձեռոցիկներ արտադրելու հնարավորություն։
                                                                Ընկերությունը  առաջարկում Gratias ապրանքային նշանի արտադրանքը։

                                                            </p>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Container>
                                        <Container className={'for_tab_first'}>
                                            <Row>
                                                <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                    <div className={'for_tab_first_title_dsc'}>
                                                        <img src={tetraSmall} alt="" className={'img-fluid title_icon'} />
                                                        <ul className={'tab_ul_desc'}>
                                                            <li>Սեղանի թղթե անձեռոցիկներ</li>
                                                        </ul>


                                                    </div>
                                                </Col>
                                                <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                    <div className={'for_tab1_image'}>
                                                        <img src={tetraBig} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>


                                    </TabPanel>

                                    <TabPanel>
                                        <Container className={'full-width-desc'} fluid>
                                            <Container>
                                                <Row>
                                                    <Col xl={{ span: 8, offset: 2 }} className={'text-center'}>
                                                        <div className={'title-description'}>
                                                            <p className={'title'}>
                                                                Էրգոպակ
                                                            </p>
                                                            <p className={'description'}>
                                                                Էրգոպակ ընկերությունը հանդիսանում է Սարանտիս խմբի անդամ։ Այն Եվրապայի 4 խոշոր ընկերություններից մեկն է,
                                                                որն ունի արտադրության ամբողջական շղթա։ Էրգոպակի արտադրանքը ներկայացված է ավելի քան 50 երկրներում։
                                                                Այն առաջարկում է տան համար նախատեսված ապրանքների մեծ տեսականի, որոնք ունեն որակի միջազագին բոլոր վկայականները։
                                                                Ընկերությունը նաև հանդիսանում է Սարանտիս խմբի մեջ մտնող մյուս ընկերությունների ապրանքների պաշտոնական ներկայացուցիչը ԱՊՀ երկրներում։
                                                                Ընկերությունը ներկայացնում է Мелочи Жизни, Domi, STR8, BU ապրանքային նշանների արտադրանքը։

                                                            </p>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Container>
                                        <Container className={'for_tab_first'}>
                                            <Row>
                                                <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                    <div className={'for_tab_first_title_dsc'}>
                                                        <img src={MelochSmall} alt="" className={'img-fluid title_icon'} />
                                                        <p className={'for_tab_desc_first'}>
                                                            <ul>
                                                                <li>Խոհանոցային սպունգեր</li>
                                                                <li>Խոհանոցային ձեռնոցներ</li>
                                                                <li>Լոգարանի սպունգեր</li>
                                                                <li>Փայլաթիթեղ</li>
                                                                <li>Աղբի տոպրակ</li>
                                                                <li>Թխման համար նախատեսված թաղանթներ</li>
                                                                <li>Լաթեր</li>
                                                            </ul>
                                                        </p>


                                                    </div>
                                                </Col>
                                                <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                    <div className={'for_tab1_image'}>
                                                        <img src={MelochBig} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className={'for_tab_first_fluid'} fluid>
                                            <Container>
                                                <Row>
                                                    <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                        <div className={'for_tab1_image'}>
                                                            <img src={DomiBig} className={'img-fluid'} alt="" />
                                                        </div>
                                                    </Col>
                                                    <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                        <div className={'for_tab_first_title_dsc'}>
                                                            <img src={DomiSmall} alt="" className={'img-fluid title_icon'} />

                                                            <p className={'for_tab_desc_first'}>
                                                                <ul>
                                                                    <li>Խոհանոցային սպունգեր</li>
                                                                    <li>Խոհանոցային ձեռնոցներ</li>
                                                                    <li>Աղբի տոպրակ</li>
                                                                    <li>Լաթեր</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Container>
                                        </Container>
                                        <Container className={'for_tab_first'}>
                                            <Row>
                                                <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                    <div className={'for_tab_first_title_dsc'}>
                                                        <img src={STR8Small} alt="" className={'img-fluid title_icon'} />
                                                        <p className={'for_tab_desc_first'}>
                                                            Տղամարդկանց համար
                                                        </p>
                                                        <p className={'for_tab_desc_first'}>
                                                            <ul>
                                                                <li>
                                                                    Հարդաջուր
                                                                </li>
                                                                <li>
                                                                    Մարմնի գել
                                                                </li>
                                                                <li>
                                                                    Լոսյոն
                                                                </li>
                                                            </ul>
                                                        </p>

                                                    </div>
                                                </Col>
                                                <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                    <div className={'for_tab1_image'}>
                                                        <img src={STR8Big} className={'img-fluid'} alt="" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className={'for_tab_first_fluid'} fluid>
                                            <Container>
                                                <Row>
                                                    <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                        <div className={'for_tab1_image'}>
                                                            <img src={BuBig} className={'img-fluid'} alt="" />
                                                        </div>
                                                    </Col>
                                                    <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                        <div className={'for_tab_first_title_dsc'}>
                                                            <img src={BuSmall} alt="" className={'img-fluid title_icon'} />
                                                            <p className={'for_tab_desc_first'}>
                                                                <ul>
                                                                    <li>
                                                                        Դեզոդորանտ սփրեյ
                                                                    </li>
                                                                    <li>
                                                                        Հարդաջուր

                                                                    </li>

                                                                </ul>
                                                            </p>


                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Container>
                                        </Container>
                                    </TabPanel>



                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Tabs>
                <Container id={'for-mobile-click'}>
                    <Row className={'row_for_brands_modal'}>
                        <Col md={6} sm={6} xs={6} className={'mt-2'}>
                            <div className={'mobile_brand_logo mt-2'} onClick={this.toggleModal}>
                                <img src={Tab1} className={'img-fluid'} style={{ transform: 'scale(0.8)' }} alt="" />
                            </div>
                            <CSSTransition
                                in={this.state.isOpen}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} style={{ float: 'right', background: 'transparent', outline: "none", border: 'none' }} onClick={this.toggleModal}>X</button>
                                    </div>
                                    <Container fluid id={'mobile-title-brand'}>
                                        <Row>
                                            <Col xl={12} className={'mobile-title'}>
                                                HYGIENE
                                            </Col>
                                            <Col sm={{ span: 10, offset: 1 }} className={'mobile-desc'}>
                                                <p className={'desc-title'}>
                                                    Հայջենիկը հանդիսանում է բազմամյա փորձով, Ռուսաստանի Դաշնության թիվ մեկ՝ կանացի բարձրորակ
                                                    հիգիենայի պարագաներ արտադրողը։ Ընկերության արտադրանքը համապատասխանում է միջազգային որակի բարձր
                                                    ստանդարտներին և արտահանվում է ավելի քան 12 երկիր։  Ընկերություննը առաջարկում է OLA, Ola SilkSense,
                                                    TerezaMed ապրանքային նշանների արտադրանքը։
                                                </p>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={OlaSmallIcon} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />
                                                    <ul className={'tab_ul_desc'}>
                                                        <li>Կանացի միջադիրներ ամենօրյա</li>
                                                        <li>Կանացի միջադիրներ հատուկ օրերի համար</li>
                                                    </ul>

                                                </div>
                                            </Col>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={OlaBigImg} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={OlaSilkBig} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={OlaSilkSmall} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />
                                                    <ul className={'tab_ul_desc'}>
                                                        <li>Բամբակե սկավառակներ</li>
                                                        <li>Բամբակե փաստարկներ</li>
                                                        <li>Խոնավ անձեռցիկներ</li>
                                                    </ul>


                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={TerezaSmall} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />
                                                    <ul className={'tab_ul_desc'}>
                                                        <li>Տակդիրներ մեծահասակների համար</li>
                                                        <li>Սավաններ մեծահասկաների համար</li>
                                                        <li>Ուրոլոգիական միջադիրներ</li>
                                                    </ul>

                                                </div>
                                            </Col>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={TerezaBig} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>

                        </Col>
                        <Col md={6} sm={6} xs={6} className={'mt-2'}>
                            <div className={'mobile_brand_logo mt-2'} onClick={this.toggleModal1}>
                                <img src={Tab2} className={'img-fluid'} alt="" />
                            </div>
                            <CSSTransition
                                in={this.state.isOpen1}
                                timeout={300}
                                classNames="dialog"
                            >

                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen1}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal1} style={{ float: 'right', background: 'transparent', outline: "none", border: 'none' }}>X</button>
                                    </div>
                                    <Container fluid id={'mobile-title-brand'}>
                                        <Row>
                                            <Col xl={12} className={'mobile-title'}>
                                                Զեթթեք
                                            </Col>
                                            <Col sm={{ span: 10, offset: 1 }} className={'mobile-desc'}>
                                                <p className={'desc-title'}>
                                                    Զեթթեքը Ռուսաստանի Դաշնությունում ամենաառաջին խոնավ անձեռոցիկների և հիգիենայի պարագաների արտադրողն է։
                                                    Արդեն 25 տարուց ավել ընկերությունը առաջարկում է բարձրորակ  թաց անձեռոցիկներ, խոնավ զուգարանի թուղթ,
                                                    օճառ և այլ հիգիենայի պարագաներ։
                                                    Ընկերությունը առաջարկում է Aquaelle,  Свежая Нота ապրանքային նշանների արտադրանքը։
                                                </p>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={AcualeSmall} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />
                                                    <ul className={'tab_ul_desc'}>
                                                        <li>Խոնավ անձեռոցիկներ</li>
                                                        <li>Զուքարանի Խոնավ թղթեր</li>
                                                        <li>Ձեռքի օճառ</li>
                                                        <li>Դեմքի կոսմետիկ դիմակ</li>
                                                    </ul>


                                                </div>
                                            </Col>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={AcualeBig} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={NootaBig} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={NootaSmall} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} style={{ width: "157px", height: "135px" }} />
                                                    <ul className={'tab_ul_desc'}>
                                                        <li>Խոնավ անձեռոցիկներ</li>
                                                        <li>Զուքարանի խոնավ թղթեր</li>
                                                    </ul>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>
                        </Col>
                        <Col md={6} sm={6} xs={6} className={'mt-2'}>
                            <div className={'mobile_brand_logo mt-2'} onClick={this.toggleModal2}>
                                <img src={Tab3} className={'img-fluid'} alt="" />
                            </div>
                            <CSSTransition
                                in={this.state.isOpen2}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen2}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} style={{ float: 'right', background: 'transparent', outline: "none", border: 'none' }} onClick={this.toggleModal2}>X</button>
                                    </div>
                                    <Container fluid id={'mobile-title-brand'}>
                                        <Row>
                                            <Col xl={12} className={'mobile-title'}>
                                                Արխբում Թիշյու Գրուպ
                                            </Col>
                                            <Col sm={{ span: 10, offset: 1 }} className={'mobile-desc'}>
                                                <p className={'desc-title'}>
                                                    «Արխբում Թիշյու Գրուպ» ՍՊԸ-ն իր ոլորտում միակ ձեռնարկությունն է Ռուսաստանի Դաշնությունում,
                                                    որը հանդիսանում է հորիզոնական ինտեգրված հոլդինգի մաս, որի շնորհիվ ընկերության արտադրանքը  շուկայում ունի
                                                    մրցակցային առավելություն։
                                                    Ընկերությունը  հագեցած է արդիական արտադրական համալիրով, որն ապահովվում է ապրանքի բարձր որակ և մրցունակ գին։
                                                    Ընկերությունը ներկայացնում է Soffione ապրանքային նշանը։

                                                </p>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={SoffiteSmall} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />
                                                    <ul className={'tab_ul_desc'}>
                                                        <li>Զուգարանի թուղթ</li>
                                                        <li>Թղթե սրբիչ</li>
                                                        <li>Թղթե անձեռոցիկներ</li>
                                                    </ul>

                                                </div>
                                            </Col>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={SoffiteBig} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>

                                </Modal>
                            </CSSTransition>

                        </Col>
                        <Col md={6} sm={6} xs={6} className={'mt-2'}>
                            <div className={'mobile_brand_logo mt-2'} onClick={this.toggleModal3}>
                                <img src={Tab4} className={'img-fluid'} alt="" />
                            </div>
                            <CSSTransition
                                in={this.state.isOpen3}
                                timeout={300}
                                classNames="dialog"
                            >

                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen3}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={this.toggleModal3} style={{ float: 'right', background: 'transparent', outline: "none", border: 'none' }}>X</button>
                                    </div>
                                    <Container fluid id={'mobile-title-brand'}>
                                        <Row>
                                            <Col xl={12} className={'mobile-title'}>
                                                ՏԵՏՐԱ
                                            </Col>
                                            <Col sm={{ span: 10, offset: 1 }} className={'mobile-desc'}>
                                                <p className={'desc-title'}>
                                                    ՏԵՏՐԱ ընկերությունը, Ռուսաստանի թղթե անձեռոցիների արտադրող ամենաժամանակակից
                                                    ընկերությությունն է։ OMET S.L.R. և  OMMAPACK իտալականն հաստոցները, հնարավորություն են տալիս
                                                    անեռոցիկների վրա ունենալ ֆոտո որակի տպագրություն և ամսական մինչև 3 մլն․ անձեռոցիկներ արտադրելու հնարավորություն։
                                                    Ընկերությունը  առաջարկում Gratias ապրանքային նշանի արտադրանքը։


                                                </p>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={tetraSmall} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />
                                                    <ul className={'tab_ul_desc'}>
                                                        <li>Սեղանի թղթե անձեռոցիկներ</li>
                                                    </ul>


                                                </div>
                                            </Col>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={tetraBig} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>

                                </Modal>
                            </CSSTransition>

                        </Col>
                        <Col md={6} sm={6} xs={6} className={'mt-2'}>
                            <div className={'mobile_brand_logo mt-2'} onClick={this.toggleModal4}>
                                <img src={Tab5} className={'img-fluid'} alt="" />
                            </div>
                            <CSSTransition
                                in={this.state.isOpen4}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={this.state.isOpen4}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} style={{ float: 'right', background: 'transparent', outline: "none", border: 'none' }} onClick={this.toggleModal4}>X</button>
                                    </div>
                                    <Container fluid id={'mobile-title-brand'}>
                                        <Row>
                                            <Col xl={12} className={'mobile-title'}>
                                                Էրգոպակ
                                            </Col>
                                            <Col sm={{ span: 10, offset: 1 }} className={'mobile-desc'}>
                                                <p className={'desc-title'}>
                                                    Էրգոպակ ընկերությունը հանդիսանում է Սարանտիս խմբի անդամ։ Այն Եվրապայի 4 խոշոր ընկերություններից մեկն է,
                                                    որն ունի արտադրության ամբողջական շղթա։ Էրգոպակի արտադրանքը ներկայացված է ավելի քան 50 երկրներում։
                                                    Այն առաջարկում է տան համար նախատեսված ապրանքների մեծ տեսականի, որոնք ունեն որակի միջազագին բոլոր վկայականները։
                                                    Ընկերությունը նաև հանդիսանում է Սարանտիս խմբի մեջ մտնող մյուս ընկերությունների ապրանքների պաշտոնական ներկայացուցիչը ԱՊՀ երկրներում։
                                                    Ընկերությունը ներկայացնում է Мелочи Жизни, Domi, STR8, BU ապրանքային նշանների արտադրանքը։
                                                </p>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={MelochSmall} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />
                                                    <p className={'for_tab_desc_first'}>
                                                        <ul>
                                                            <li>Խոհանոցային սպունգեր</li>
                                                            <li>Խոհանոցային ձեռնոցներ</li>
                                                            <li>Լոգարանի սպունգեր</li>
                                                            <li>Փայլաթիթեղ</li>
                                                            <li>Աղբի տոպրակ</li>
                                                            <li>Թխման համար նախատեսված թաղանթներ</li>
                                                            <li>Լաթեր</li>
                                                        </ul>
                                                    </p>


                                                </div>
                                            </Col>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={MelochBig} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={DomiBig} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={DomiSmall} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />

                                                    <p className={'for_tab_desc_first'}>
                                                        <ul>
                                                            <li>Խոհանոցային սպունգեր</li>
                                                            <li>Խոհանոցային ձեռնոցներ</li>
                                                            <li>Աղբի տոպրակ</li>
                                                            <li>Լաթեր</li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={STR8Small} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />
                                                    <p className={'for_tab_desc_first'}>
                                                        Տղամարդկանց համար
                                                    </p>
                                                    <p className={'for_tab_desc_first'}>
                                                        <ul>
                                                            <li>
                                                                Հարդաջուր
                                                            </li>
                                                            <li>
                                                                Մարմնի գել
                                                            </li>
                                                            <li>
                                                                Լոսյոն
                                                            </li>
                                                        </ul>
                                                    </p>

                                                </div>
                                            </Col>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={STR8Big} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className={'base_section_for_brand_mobile_size'}>
                                        <Row className={'first_base_row'}>
                                            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} sm={{ span: 8, offset: 2 }}>
                                                <div className={'for_tab1_image'}>
                                                    <img src={BuBig} className={'img-fluid'} alt="" />
                                                </div>
                                            </Col>
                                            <Col xl={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} sm={{ span: 10, offset: 1 }}>
                                                <div className={'for_tab_first_title_dsc'}>
                                                    <img src={BuSmall} alt="" className={'img-fluid title_icon'} id={'small_icon_for_modal'} />
                                                    <p className={'for_tab_desc_first'}>
                                                        <ul>
                                                            <li>
                                                                Դեզոդորանտ սփրեյ
                                                            </li>
                                                            <li>
                                                                Հարդաջուր
                                                            </li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>

                                </Modal>
                            </CSSTransition>
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }
}
