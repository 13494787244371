import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
// import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Context} from "../../components/Wrapper";
import '../../assets/css/Footer/style.css';
import logo from '../../assets/img/home/logoWhite.png';
import phone_iconWhite from "../../assets/img/home/iconwhite.png";
import aaa from '../../assets/img/home/email_icon.png';
import addressIcon from '../../assets/img/home/addressIcon.png';
import faceIcon from '../../assets/img/home/face.png';
import instaIcon from '../../assets/img/home/insta.png';
import copy from '../../assets/img/home/copy.png';
function Footer() {
    return (
        <div>
            <Container fluid className={'footer'}>
                <Container>
                    <Row className={'first_row'}>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{span:3,offset:0}} sm={{span:6,offset:0}} xs={{span:12,offset:0}} className={'first_row_first_col mt-4'}>
                            <div>
                                <img src={logo} alt={''} className={'img-fluid'} />
                            </div>
                            <div className={'for_see_more_Link'}>
                                <Link to={''} className={'for_see_more_link'}>
                                    <FormattedMessage id={'online_shop'} />
                                </Link>
                            </div>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{span:3,offset:0}} sm={{span:6,offset:0}} xs={{span:12,offset:0}} className={'first_row_second_col mt-4'}>
                            <span>
                                <FormattedMessage id={'nav_second'} />
                            </span>
                            <ul>
                                <li>
                                    <Link to="/brands">
                                        <FormattedMessage id={'link_brands'} />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/service">
                                        <FormattedMessage id={'link_service'} />
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{span:3,offset:0}} sm={{span:6,offset:0}} xs={{span:12,offset:0}} className={'first_row_third_col mt-4'}>
                            <span>
                                <FormattedMessage id={'nav_third'} />
                            </span>
                            <ul>
                                <li>
                                    <Link to="/products">
                                        <FormattedMessage id={'product_napkins'} />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/products">
                                        <FormattedMessage id={'product_toilet'} />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/products">
                                        <FormattedMessage id={'product_napkins'} />
                                    </Link>
                                </li>
                                <li>
                                <Link to="/other" eventKey={'wetNapkins'}>
                                        <FormattedMessage id={'product_wet_napkins'} />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/products">
                                        <FormattedMessage id={'product_horeka'} />
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:3,offset:0}} md={{span:3,offset:0}} sm={{span:6,offset:0}} xs={{span:12,offset:0}} className={'first_row_forth_col mt-4'}>
                            <div>
                                <p>
                                    <FormattedMessage id={'nav_forth'} />
                                </p>
                                <p className={'margin-none'}>
                                    <Link to="/representation">
                                        <FormattedMessage id={'nav_fifth'} />
                                    </Link>
                                </p>
                                <p className={'margin-none'}>
                                    <Link to="/career">
                                        <FormattedMessage id={'nav_sixth'} />
                                    </Link>
                                </p>
                                <p  className={'margin-none'}>
                                    <Link to="/news">
                                        <FormattedMessage id={'nav_seventh'} />
                                    </Link>
                                </p>

                            </div>
                        </Col>
                    </Row>
                    <Row className={'second_row'}>
                        <Col xl={{span:9,offset:0}} lg={{span:8,offset:0}} md={{span:7,offset:0}} sm={{span:12,offset:0}} className={'map_computer_size'}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d762.3926299201187!2d44.51155189019768!3d40.151849507770905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1612038887020!5m2!1sru!2s"
                                width="100%" height="307px" allowFullScreen="" frameBorder="0" aria-hidden="false"
                                tabIndex="0"></iframe>
                        </Col>
                        <Col xl={{span:3,offset:0}} lg={{span:4,offset:0}} md={{span:5,offset:0}} sm={{span:10,offset:2}} xs={{span:12,offset:0}} className={'right_for_us'}>
                            <p className={'call_us'}>
                                <FormattedMessage id={'nav_eight'} />
                            </p>
                            <div className={'number_icon'}>
                                <div className={'phone_icon'}>
                                    <img src={phone_iconWhite} alt={'Phone_icon'} id={'white_icon'} />
                                </div>
                                <div className={'phone_numbers'}>
                                    <ul>
                                        <li><a href='tel:+374 11 277 070'>+374 11 277 070</a></li>
                                        <li><a href='tel:+374 55 277 070'>+374 55 277 070</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className={'email'}>
                                <div className={'email_icon'}>
                                    <img src={aaa} alt={'Email Soft Traid'} />
                                </div>
                                <div className={'email_inbox'}>
                                    <a href="mailto:soft.trade@inbox.ru">
                                        soft.trade@inbox.ru
                                    </a>
                                </div>
                            </div>
                            <div className={'adress'}>
                                <div className={'address_icon'}>
                                    <img src={addressIcon} alt={'Phone_icon'} id={'white_icon'} />
                                </div>
                                <div className={'address_soft'}>
                                    <ul>
                                        <li>
                                            <FormattedMessage id={'address1'} />

                                        </li>
                                        <li>
                                            <FormattedMessage id={'address2'} />

                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={'social_icons'}>
                                <div className={'facebook_link'}>
                                    <a href="">
                                        <img src={faceIcon} alt={''} />
                                    </a>
                                </div>
                                <div className={'instagram_link'}>
                                    <a href="">
                                        <img src={instaIcon} alt={''} />

                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col xl={12} className={'map_for_mobile pt-3'}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d762.3926299201187!2d44.51155189019768!3d40.151849507770905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1612038887020!5m2!1sru!2s"
                                width="100%" height="200px" allowFullScreen="" frameBorder="0" aria-hidden="false"
                                tabIndex="0"></iframe>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className={'for_copyright'}>
                <Row>
                    <Col xl={12}>
                        <ul>
                            <li>Copyright</li>
                            <li><img src={copy} alt=""/></li>
                            <li>2021</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Footer


