import React, {useContext} from 'react';
import {Context} from "../../components/Wrapper";
import ArmFlag  from '../../assets/img/header/amFlag.png';
import '../../assets/js/script';
import RuFlag  from '../../assets/img/header/ruFlag.png';
import EnFlag  from '../../assets/img/header/enFlag.png';
import '../../assets/css/Component/style.css';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
function Lang (){
    const context = useContext(Context);
    const sectionStyle = {
        width: "100%",
        height: "400px",
        backgroundImage: "url(" + { ArmFlag } + ")",
        backgroundSize:'cover',
        backgroundPosition:'center',
        zIndex:'0'
    };
    return (
        <>
            <div>
                {/*<img src={ArmFlag} alt=""/>*/}
                {/*<select value = {context.locale} onChange={context.selectLanguage}>*/}
                {/*    <option value='am' id={'armenian'}  data-thumbnail="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/LetterA.svg/2000px-LetterA.svg.png">*/}

                {/*    </option>*/}
                {/*    <option value='ru'>Russian</option>*/}
                {/*    <option value='en'>English</option>*/}

                {/*</select>*/}
                <Select
                    value = {context.locale} onChange={context.selectLanguage}

                >
                    <MenuItem value={'am'} id={'aaa'}  style={{zIndex:'999999999999999 !important'}}>
                        <img src={ArmFlag} alt=""/>
                    </MenuItem>
                    <MenuItem value={'ru'}>
                        <img src={RuFlag} alt=""/>
                    </MenuItem>
                    <MenuItem value={'en'}>
                        <img src={EnFlag} alt=""/>
                    </MenuItem>
                </Select>
            </div>
            {/*<div className="drop-down">*/}
            {/*    <select name="options">*/}
            {/*        <option className="en" value="am"*/}
            {/*                style={{ backgroundImage: ArmFlag}}>Armenian*/}
            {/*        </option>*/}
            {/*        <option className="fr" value="ru"*/}
            {/*                style={{ backgroundImage: RuFlag}}>Russian*/}
            {/*        </option>*/}
            {/*        <option className="nl" value="en"*/}
            {/*                style={{ backgroundImage: EnFlag}}>English*/}
            {/*        </option>*/}
            {/*    </select>*/}
            {/*</div>*/}
        </>
)
}
export default Lang;