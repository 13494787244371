import React ,{useState} from  'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";
import '../../../assets/css/Product/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Modal from "react-modal";
import ReactModal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../../../assets/css/ModalCarousel/style.css";
//import brands img
import SilkLogo from '../../../assets/img/home/logo/SilkLogo.webp';
import PianoLogo from '../../../assets/img/home/logo/piano svg.webp';
import VitaLogo from '../../../assets/img/home/logo/vita svg.webp';

import silk0085 from  "../../../assets/img/product/Cotton/silk/0085 bambak 100g.jpg";
import silk0112 from  "../../../assets/img/product/Cotton/silk/0112 80h disk.jpg";
import silk00129 from  "../../../assets/img/product/Cotton/silk/00129 100h disk.jpg";
import silk00136 from  "../../../assets/img/product/Cotton/silk/00136 130h disk.jpg";
import silk4104 from  "../../../assets/img/product/Cotton/silk/4104 tup 200.jpg";
import silk4111 from  "../../../assets/img/product/Cotton/silk/4111 tup 100.jpg";
import silk4128 from  "../../../assets/img/product/Cotton/silk/4128 100h zip.jpg";
import silk4135 from  "../../../assets/img/product/Cotton/silk/4135 200 zip.jpg";
import silk8073 from  "../../../assets/img/product/Cotton/silk/8073 baby 50.jpg";
import silk10078 from  "../../../assets/img/product/Cotton/silk/10078 bambak 50g.jpg";
import silk200_1 from  "../../../assets/img/product/Cotton/silk/akanji chop 200-1.jpg";
import silk200_2 from  "../../../assets/img/product/Cotton/silk/akanji chop 200-2.jpg";

import piano1204 from "../../../assets/img/product/Cotton/piano/1204 piano bambak 50g.png";
import piano4173Tup100 from "../../../assets/img/product/Cotton/piano/4173 bambake paytik 100h tupov.jpg";
import piano4173_100 from "../../../assets/img/product/Cotton/piano/4173 bambake paytik 100h.jpg";
import piano1204Tup200 from "../../../assets/img/product/Cotton/piano/4173 bambake paytik 200h tupov.jpg";
import piano1204_200 from "../../../assets/img/product/Cotton/piano/4173 bambake paytik 200h.jpg";
import piano1211 from "../../../assets/img/product/Cotton/piano/piano bambak 80g 1211.png";

//Kitchen 3

//Kitchen 3 end
// const props = {};

export default class Cotton extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showModal: false,
            showModal2: false,

        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseModal2 = this.handleCloseModal2.bind(this);
    }
    handleOpenModal () {
        this.setState({ showModal: true });
    }
    handleOpenModal2 () {
        this.setState({ showModal2: true });
    }
    handleCloseModal () {
        this.setState({ showModal: false });
    }
    handleCloseModal2 () {
        this.setState({ showModal2: false });
    }
    render() {
        return (
            <>
                <Tabs>
                    <Container fluid className={'row-for-tab-lists'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList style={{justifyContent:"center"}}>
                                    <Tab>
                                        <img src={SilkLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                    <Tab>
                                        <img src={PianoLogo} className={'img-fluid'} alt=""/>
                                    </Tab>
                                    {/*<Tab>*/}
                                    {/*    <img src={VitaLogo} className={'img-fluid'} alt=""/>*/}
                                    {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'row-for-tab-lists-mobile'} >
                        <Row>
                            <Col xl={{span:12,offset:0}}  className={''}>
                                <TabList>
                                    <Tab>
                                        <FormattedMessage id={'silk_brand'} />
                                    </Tab>
                                    <Tab>
                                        <FormattedMessage id={'piano_brand'} />
                                    </Tab>
                                    {/*<Tab>*/}
                                    {/*    <FormattedMessage id={'vita_brand'} />*/}
                                    {/*</Tab>*/}
                                </TabList>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={'p-0'}>
                        <Row>
                            <Col className={'for_border_bottom'}></Col>
                        </Row>
                    </Container>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0085} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'ctn'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>100 գրամ</p>
                                            <p className={'tab-content-desc-articule'}  style={{fontSize:"14px",paddingLeft:"0px"}}>0085</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk10078} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'}>
                                                <FormattedMessage id={'ctn'} />
                                            </p>
                                            <p className={'tab-content-desc-size'}>50 գրամ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>1N078</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk0112} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե սկավառակներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>80 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0112</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk00129} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե սկավառակներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>100 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0129</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk00136} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'} >
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե սկավառակներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>130 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0136</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4104} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>200 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>4104</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4111} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'} >
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>100 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>4111</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4128} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>100 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>4128</p>
                                        </div>
                                    </div>
                                </Col>


                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk4135} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>200 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>4135</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk8073} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>50 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>8073</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk200_1} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>200 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0200</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={silk200_2} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>200 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>0200</p>
                                        </div>
                                    </div>
                                </Col>



                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel>
                        <Container fluid className={'row_for_napkins_img'}>
                            <Row>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1204} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակ


                                            </p>
                                            <p className={'tab-content-desc-size'}> 50 գրամ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>1204</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1211} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակ


                                            </p>
                                            <p className={'tab-content-desc-size'}> 80 գրամ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}>1211</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano4173Tup100} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>100 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}></p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano4173_100} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>100 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}></p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1204Tup200} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>200 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}></p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={4} lg={6}  xs={6} className={'mt-2 col-for-product-card'}>
                                    <div className={'section_for_tab_content'}>
                                        <div className={'for_tab_content_img'}>
                                            <img src={piano1204_200} className={'img-fluid'} style={{maxHeight:"140px"}}   title={'Click'} alt=""/>
                                        </div>
                                        <div className={'for_tab_content_text'}>
                                            <p className={'tab-content-title'} style={{fontSize:"14px"}}>
                                                {/*<FormattedMessage id={'ctn'} />*/}
                                                Բամբակե փայտիկներ
                                            </p>
                                            <p className={'tab-content-desc-size'}>200 հատ</p>
                                            <p className={'tab-content-desc-articule'} style={{fontSize:"14px",paddingLeft:"0px"}}></p>
                                        </div>
                                    </div>
                                </Col>



                            </Row>
                        </Container>
                    </TabPanel>



                </Tabs>
            </>
        )
    }
}
