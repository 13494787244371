import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Context } from "./components/Wrapper";
import Header from "./Pages/Components/Header";
import Footer from "./Pages/Components/Footer";
import Lang from "./Pages/Components/SetLanguage";
import ScrollToTop from "./Pages/Components/Pages/ScrollToTop";
import Home from "./Pages/Components/Pages/Home";
import About from "./Pages/Components/Pages/About";
import Brands from "./Pages/Components/Pages/Brands";
import Service from "./Pages/Components/Pages/Service";
import Partners from "./Pages/Components/Pages/Partners";
import News from "./Pages/Components/Pages/News";
import Career from "./Pages/Components/Pages/Career";
import Representation from "./Pages/Components/Pages/Representation";
import Products from "./Pages/Components/Pages/Product";
import NapkinsBox from "./Pages/Components/ProductComponents/NapkinsBox";
import Routes from "./routes.jsx";
import ProductOther from "./Pages/Components/ProductComponents/Other";
import Product from "./Pages/Product/index";
import setAuthToken from "./helpers/setAuthToken";
import store from "./data/store";
import { loadUser } from "./data/reducers/auth";
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  const context = useContext(Context);
  useEffect(() => {
    console.log("app");
    store.dispatch(loadUser());
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* <header className="App-header"> */}
      <Header />
      {/* </header> */}
      <div className="App">
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/brands" component={Brands} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/partners" component={Partners} />
        <Route exact path="/news" component={News} />
        <Route exact path="/career" component={Career} />
        <Route exact path="/representation" component={Representation} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/other" component={ProductOther} />
        <Route exact path="/Product" component={Product} />
      </div>

      <Routes />
      {/* <footer className={"App-footer"}> */}
      <Footer />
      {/* </footer> */}
    </BrowserRouter>
  );
}

export default App;
