import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {Container,Row,Col} from "react-bootstrap";
import {FormattedMessage} from 'react-intl';
//css Link
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Header/style.css';
//images
import SoftTradeLogo from '../../assets/img/header/SoftTraidLogo.png';
import phone_icon from '../../assets/img/header/phone_icon.png';
import phone_iconWhite from '../../assets/img/header/iconwhite.png';
import amFlag from '../../assets/img/header/amFlag.png';
import ruFlag from '../../assets/img/header/ruFlag.png';
import enFlag from '../../assets/img/header/enFlag.png';
import NavBar from '../Components/navbar';
import Lang from './SetLanguage';
function Header() {

    return (
        <>
            <Container id={'header_first_row_for_big_size'}>
                <Row>
                    <Col xl={3} lg={3} md={6} sm={6} xs={12} className={'logo_section'}>
                        <Link to='/'>
                            <img src={SoftTradeLogo} alt={'Soft Traid Logo'} className={'img-fluid'} />
                        </Link>
                    </Col>
                    <Col xl={4} lg={3} md={6} sm={6} className={'search_section'}>
                        {/*<input type={'text'} placeholder={<FormattedMessage  id = {"srch_placehorder"} />} />*/}

                        <FormattedMessage id="srch_placehorder" defaultMessage="search" >
                            {placeholder=>
                                <input placeholder={placeholder} id={"input_form_computer_size"} className={'px-3 w-100'} style={{border:'none',outline:'none'}} />

                            }
                        </FormattedMessage>
                        {/*<FontAwesomeIcon icon={faSearch} className={'search_icon'} />*/}


                    </Col>
                    <Col xl={2} lg={2} md={6} sm={5} className={'online_shop_section'}>
                        <Link id={'online_shop'}>
                            <FormattedMessage id={'online_shop'} />
                        </Link>
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={7} className={'phone_and_language_computer_size'}>
                        <div className={'phone_icon'}>
                            <img src={phone_icon} alt={'Phone_icon'}  id={'phone_icon'}  />
                            <img src={phone_iconWhite} alt={'Phone_icon'} id={'white_icon'} />
                        </div>
                        <div className={'phone_numbers'}>
                            <ul>
                                <li><a href='tel:+374 11 277 070' className={'phone_link'}>+374 11 277 070</a></li>
                                <li><a href='tel:+374 55 277 070' className={'phone_link'}>+374 55 277 070</a></li>
                            </ul>
                        </div>
                        <div className={'for_drop'}>
                                {/*<span style={{paddingBottom:'12px'}}>*/}
                                {/*    /!*<img src={src} alt=""/>*!/*/}
                                {/*</span>*/}
                            <Lang />
                        </div>
                         </Col>
                </Row>
            </Container>
            <NavBar />

        </>
    )
}
export default Header


