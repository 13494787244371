import React from "react";
import {Container,Row,Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import "../../../assets/css/About/style.css";
import {FormattedMessage} from "react-intl";
import ServiceBig1 from '../../../assets/img/service/ServiceBig1.webp';
import HorekaBig from '../../../assets/img/service/horeka1.jpg';
import leftSlaq from '../../../assets/img/service/leftSlaq.png';
import logistic from "../../../assets/img/service/Exports-image-1.webp";
export default class Service extends React.Component {
    render() {
        return (
            <>
                <Container className={'first-section-for-serice'}>
                    <Row>
                        <Col xl={{span:8,offset:2}} lg={{span:8,offset:2}}>
                            <p className={'service-title'}>
                                <FormattedMessage id={'link_service'} />
                            </p>
                            <p className={'service-desc'}>
                                «Սոֆթ Թրեյդ» ՍՊԸ-ն առաջարկում է պայմանագրային արտադրության, HoReCa շուկայի տնտեսական ապրանքների մատակարարման և միջազգային բեռնափոխադրման ծառայություններ։
                            </p>

                        </Col>
                    </Row>
                </Container>
                <Container className={'second-section-for-serice'}>
                    <Row>
                        <Col xl={{span:4,offset:0}} lg={{span:4,offset:0}} md={{span:4,offset:0}} sm={{span:5,offset:0}} xs={{span:12,offset:0}} className={'left-content-img'}>
                            <img src={ServiceBig1} className={'img-fluid w-100'} alt=""/>
                        </Col>
                        <Col xl={{span:8,offset:0}} lg={{span:8,offset:0}} md={{span:8,offset:0}} sm={{span:7,offset:0}} className={''}>
                            <div className={'right-content-text'}>
                                <p className={'product-title'}>
                                    <FormattedMessage id={'service_production'} />
                                </p>
                                <p className={'product-desc'}>
                                    «Սոֆթ Թրեյդ» ՍՊԸ-ն ունի մեծ փորձ պայմանագրային արտադրության ոլորտում։ Իրենց սեփական ապրանքային նշանի տակ արտադրանքի արտադրությունը
                                    մեզ են վստահում Հայաստանի լավագույն ցանցային վաճառակետերը, սրճարանները, հյուրանոցները և այլ ոլորտի ընկերությունները։
                                    Առաջարկում ենք անձեռոցիկների, զուգարանի թղթի, թղթե սրբիչների, ստվարաթղթե տոպրակների, խոնավ անձեռոցիկների և այլ ապրանքների արտադրություն հաճախորդի ապրանքանիշով։
                                </p>

                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className={'third-section-for-serice'}>
                    <Row>
                        <Col xl={{}} className={'img-content mobile-block'}>
                            <img src={HorekaBig} className={'img-fluid w-100'} alt=""/>
                        </Col>
                        <Col xl={{span:8,offset:0}} lg={{span:7,offset:0}} md={{span:7,offset:0}} sm={{span:7,offset:0}} className={'Big_text_content'}>
                            <div className={'right-content-text'}>
                                <p className={'product-title'}>
                                    HoReCa շուկայի տնտեսական ապրանքների մատակարարում
                                </p>
                                <p className={'product-desc'}>
                                    «Սոֆտ Թրեյդ» ՍՊԸ-ն առաջարկում է մաքրող, լվացող, ախտահանող և այլ տնտեսական ապրանքների լայն տեսականի, որն օգտագործվում է ռեստորանների, սրճարանների և հյուրանոցների  գործունեության մեջ։

                                </p>
                            </div>
                        </Col>
                        <Col xl={{span:4,offset:0}} lg={{span:5,offset:0}} md={{span:5,offset:0}} sm={{span:5,offset:0}} className={'img-content mobile-none'}>
                            <img src={HorekaBig} className={'img-fluid w-100'} alt=""/>
                        </Col>
                    </Row>
                </Container>
                <Container className={'forth-section-for-serice'}>
                    <Row>
                        <Col xl={{span:4,offset:0}} lg={{span:4,offset:0}} md={{span:5,offset:0}} sm={{span:5,offset:0}} className={'left-content-img'}>
                            <img src={logistic} className={'img-fluid w-100'} alt=""/>
                        </Col>
                        <Col xl={{span:8,offset:0}} lg={{span:8,offset:0}} md={{span:7,offset:0}} sm={{span:7,offset:0}} className={'right-contect-fluid'}>
                            <div className={'right-content-text'}>
                                <p className={'product-title'}>
                                    <FormattedMessage id={"transfer"} />
                                </p>
                                <p className={'product-desc'}>
                                    Ունենալով մեծ փորձ միջազգային բեռնափոխադրման ոլորտում՝ «Սոֆթ Թրեյդ» ՍՊԸ-ն սեփական բեռնատար մեքենաներով  տրամադրում է
                                    միջազգային բեռնափոխադրման ծառայություններ՝ Հայաստանից դեպի Ռուսաստանի Դաշնություն և հակառակ ուղղությամբ։
                                    Մեքենաները հագեցած են GPS արբանյակային մոնիտորինգային համակարգով, պահպանում են անհրաժեշտ ջարմային պայմանները,
                                    կարող են տեղափոխել մինչև 20 տոննա բեռ։

                                </p>

                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col xl={12} className={'end-prew'}>
                            <Link to='/about'>
                                <span><img src={leftSlaq} alt=""/></span><span>
                                <FormattedMessage id={'return'} />
                            </span>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}




